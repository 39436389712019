import { CSSProperties, FC } from "react";

interface SquareProps {
  className?: string;
  style: CSSProperties;
}

export const Square: FC<SquareProps> = ({ className, style }) => (
  <svg
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <rect x="0.5" y="0.5" width="3" height="3" fill="white" stroke="#0D99FF" />
  </svg>
);
