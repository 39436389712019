import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Accordion,
  AccordionItem,
  Checkbox,
  Tabs,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  ClickableTile,
  Theme,
  ExpandableTile,
  TileAboveTheFoldContent,
  TileBelowTheFoldContent,
  Link,
} from "@carbon/react";
/* eslint-disable import/no-internal-modules */
import {
  BorderFull,
  BorderBottom,
  BorderTop,
  ChartScatter,
  ChartLine,
  ChartRing,
  ChartColumn,
  CaretLeft,
  CaretRight,
  CrossTab,
  ChartStacked,
  ChartBarStacked,
  RightPanelClose,
  ArrowRight,
  TextBold,
  TextItalic,
  TextUnderline,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignRight,
} from "@carbon/react/icons";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggleGroup";
import { NoBarScroll } from "@/components/ui/nobarscroll";
import { Slider } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Input } from "@/components/ui/input";

/* eslint-enable import/no-internal-modules */
import { ValueFormatterParams } from "ag-grid-community";
import { GetAvailableAnalysesOutput } from "../../../../../../../core/model/api/analysis";

interface ChartEditorProps {
  showChartHeader: boolean;
  setShowChartHeader: (show: boolean) => void;
  setChartHeaderBorder: (border: any) => void;
  // Data labels
  chartDataLabelDecimalPlaces: number;
  setChartDataLabelDecimalPlaces: (decimalPlaces: number) => void;
  decimalPlaces: number;
  setDecimalPlaces: (decimalPlaces: number) => void;
  isChartDataLabelBackgroundTransparent: boolean;
  onChartDataLabelBackgroundTransparentChange: (isTransparent: boolean) => void;
  isChartDataLabelUnderlined: boolean;
  setIsChartDataLabelUnderlined: (isUnderlined: boolean) => void;
  isChartDataLabelOn: boolean;
  setIsChartDataLabelOn: (isOn: boolean) => void;
  chartDataLabelFormat: string;
  setChartDataLabelFormat: (format: string) => void;
  chartDataLabelFontSize: number;
  setChartDataLabelFontSize: (fontSize: number) => void;
  chartDataLabelColor: string;
  setChartDataLabelColor: (color: string) => void;
  isChartDataLabelBold: boolean;
  setIsChartDataLabelBold: (isBold: boolean) => void;
  isChartDataLabelItalic: boolean;
  setIsChartDataLabelItalic: (isItalic: boolean) => void;
  chartDataLabelBackgroundColor: string;
  setChartDataLabelBackgroundColor: (color: string) => void;
  chartDataLabelBackgroundSize: number;
  setChartDataLabelBackgroundSize: (size: any) => void;
  subCategoryLabel: string;
  onSubCategoryLabelChange: (label: string) => void;
  chartQualifierLabel: string;
  onChartQualifierLabelChange: (label: any) => void;
  chartType: string;
  stacked: boolean;
  axisSwitched: boolean;
  chartOptions?: any;
  onChartToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  onTableToggle: (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => void;
  onUpdateChartOptions: (options: {
    type?: any;
    [key: string]: any;
    series?: any;
    resizable?: boolean;
    donut?: boolean;
    legend?: boolean;
  }) => void;

  //Chart legend
  isLegendEnabled: boolean;
  setIsLegendEnabled: (enabled: boolean) => void;
  legendPosition: any;
  setLegendPosition: (position: any) => void;
  legendOrientation: any;
  setLegendOrientation: (orientation: any) => void;
  legendSpacing: number;
  setLegendSpacing: (spacing: number) => void;

  legendItemMarkerSize: number;
  setLegendItemMarkerSize: (size: number) => void;
  legendItemMarkerShape: any;
  setLegendItemMarkerShape: (shape: any) => void;
  legendItemMarkerPadding: number;
  setLegendItemMarkerPadding: (padding: number) => void;
  legendItemMarkerStrokeWidth: number;
  setLegendItemMarkerStrokeWidth: (strokeWidth: number) => void;

  legendItemLineStrokeWidth: number;
  setLegendItemLineStrokeWidth: (strokeWidth: number) => void;
  legendItemLineLength: number;
  setLegendItemLineLength: (length: number) => void;

  legendItemLabelColor: string;
  setLegendItemLabelColor: (color: string) => void;
  legendItemLabelFontStyle: any;
  setLegendItemLabelFontStyle: (fontStyle: any) => void;
  legendItemLabelFontWeight: any;
  setLegendItemLabelFontWeight: (fontWeight: any) => void;
  legendItemLabelFontSize: number;
  setLegendItemLabelFontSize: (fontSize: number) => void;
  legendItemLabelFontFamily: string;
  setLegendItemLabelFontFamily: (fontFamily: string) => void;

  legendItemPaddingX: number;
  setLegendItemPaddingX: (paddingX: number) => void;
  legendItemPaddingY: number;
  setLegendItemPaddingY: (paddingY: number) => void;

  chartBackgroundColor: string;
  onChartBackgroundColorChange: (color: string) => void;
  isChartBackgroundTransparent: boolean;
  onChartBackgroundTransparentChange: (isTransparent: boolean) => void;
  chartBorder: string;
  onChartBorderChange: (border: string) => void;
  chartBorderColor: string;
  onChartBorderColorChange: (color: string) => void;
  isAxisGridLines: boolean;
  onAxisGridLinesChange: (isAxisGridLines: boolean) => void;
  analyses: GetAvailableAnalysesOutput;
  onChartTypeChange: (newType: string, stacked: boolean) => void;
  chartHeader: string;
  onChartHeaderChange: (header: string) => void;
  chartHeaderBackgroundColor: string;
  onChartHeaderBackgroundColorChange: (color: string) => void;
  chartHeaderTextColor: string;
  onChartHeaderTextColorChange: (color: string) => void;
  chartHeaderFontFamily: string;
  onChartHeaderFontFamilyChange: (fontFamily: string) => void;
  chartHeaderFontSize: number;
  onChartHeaderFontSizeChange: (fontSize: number) => void;
  chartHeaderTextStyle: any;
  onChartHeaderTextStyleChange: (textStyle: any) => void;
  chartHeaderTextAlignment: string;
  onChartHeaderTextAlignmentChange: (textAlignment: string) => void;
  chartHeaderBorder: any;
  onChartHeaderBorderChange: (border: any) => void;
  chartHeaderBorderColor: any;
  onChartHeaderBorderColorChange: (color: string) => void;
  chartDataLabelFontFamily: string;
  onChartDataLabelFontFamilyChange: (fontFamily: string) => void;
}

export function currencyFormatter(
  params: ValueFormatterParams,
  formatOptions: { selectedFormat: string; decimalPlaces: number },
) {
  const number = Number.parseFloat(params.value);
  if (Number.isNaN(number)) {
    return "-";
  }
  if (number === 0) {
    return "-";
  }

  let currency = "USD"; // Default currency
  if (formatOptions.selectedFormat === "currencyEUR") {
    currency = "EUR";
  } else if (formatOptions.selectedFormat === "currencyGBP") {
    currency = "GBP";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: formatOptions.decimalPlaces,
    maximumFractionDigits: formatOptions.decimalPlaces,
  });

  return formatter.format(number);
}

export function percentFormatter(
  params: ValueFormatterParams,
  formatOptions: { selectedFormat: string; decimalPlaces: number },
) {
  const number = Number.parseFloat(params.value);
  if (Number.isNaN(number)) {
    return "-";
  }
  if (number === 0) {
    return "-";
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: formatOptions.decimalPlaces,
    maximumFractionDigits: formatOptions.decimalPlaces,
  });

  const formattedNumber = formatter.format(number / 100); // Convert to a fraction for percent formatting
  return number > 0 ? formattedNumber : `(${formattedNumber})`;
}

export function textFormatter(params: ValueFormatterParams) {
  // Simply return the value as a string
  return params.value.toString();
}

export function getFormatterFunction(format: string, decimalPlaces: number) {
  switch (format) {
    case "percent": {
      return (params: ValueFormatterParams) =>
        percentFormatter(params, { selectedFormat: format, decimalPlaces });
    }
    case "text": {
      return (params: ValueFormatterParams) => textFormatter(params);
    }
    default: {
      return (params: ValueFormatterParams) =>
        currencyFormatter(params, { selectedFormat: format, decimalPlaces });
    }
  }
}

export const ChartEditor: React.FC<ChartEditorProps> = ({
  stacked,
  chartType,
  showChartHeader,
  setShowChartHeader,
  onChartTypeChange,

  onUpdateChartOptions,
  onTableToggle,
  onChartToggle,
  chartBackgroundColor,
  onChartBackgroundColorChange,
  isChartBackgroundTransparent,
  onChartBackgroundTransparentChange,
  isAxisGridLines,
  onAxisGridLinesChange,
  analyses,
  // Data labels

  chartDataLabelDecimalPlaces,
  setChartDataLabelDecimalPlaces,
  isChartDataLabelUnderlined,
  setIsChartDataLabelUnderlined,
  isChartDataLabelOn,
  setIsChartDataLabelOn,
  chartDataLabelFormat,
  setChartDataLabelFormat,
  chartDataLabelFontSize,
  setChartDataLabelFontSize,
  chartDataLabelColor,
  setChartDataLabelColor,
  isChartDataLabelBold,
  setIsChartDataLabelBold,
  isChartDataLabelItalic,
  setIsChartDataLabelItalic,
  chartDataLabelBackgroundColor,
  setChartDataLabelBackgroundColor,

  setChartDataLabelBackgroundSize,
  chartDataLabelFontFamily,
  onChartDataLabelFontFamilyChange,
  // Header
  chartHeader,
  onChartHeaderChange,
  chartHeaderBackgroundColor,
  onChartHeaderBackgroundColorChange,
  chartHeaderTextColor,
  onChartHeaderTextColorChange,
  chartHeaderFontFamily,
  onChartHeaderFontFamilyChange,
  chartHeaderFontSize,
  onChartHeaderFontSizeChange,
  chartHeaderTextStyle,
  onChartHeaderTextStyleChange,
  setChartHeaderBorder,
  chartHeaderTextAlignment,
  onChartHeaderTextAlignmentChange,
  chartHeaderBorderColor,
  onChartHeaderBorderColorChange,
  chartHeaderBorder,
  // eslint-disable-next-line no-unused-vars
  subCategoryLabel,
  // eslint-disable-next-line no-unused-vars
  onSubCategoryLabelChange,
  // eslint-disable-next-line no-unused-vars
  chartQualifierLabel,
  // eslint-disable-next-line no-unused-vars
  onChartQualifierLabelChange,
  // Chart legend

  isLegendEnabled,
  setIsLegendEnabled,
  legendPosition,
  setLegendPosition,
  legendOrientation,
  setLegendOrientation,
  legendSpacing,
  setLegendSpacing,
  legendItemMarkerSize,
  setLegendItemMarkerSize,
  legendItemMarkerShape,
  setLegendItemMarkerShape,
  legendItemMarkerPadding,
  setLegendItemMarkerPadding,
  legendItemMarkerStrokeWidth,
  setLegendItemMarkerStrokeWidth,

  legendItemLabelColor,
  setLegendItemLabelColor,
  legendItemLabelFontStyle,
  setLegendItemLabelFontStyle,
  legendItemLabelFontWeight,
  setLegendItemLabelFontWeight,
  legendItemLabelFontSize,
  setLegendItemLabelFontSize,
  legendItemLabelFontFamily,
  setLegendItemLabelFontFamily,
  legendItemPaddingX,
  setLegendItemPaddingX,
  legendItemPaddingY,
  setLegendItemPaddingY,
}) => {
  // Renamed state variable for clarity and to avoid conflict

  const [xAxisLabelFormat, setXAxisLabelFormat] = useState("text");
  const [yAxisLabelFormat, setYAxisLabelFormat] = useState("default");
  const [decimalPlaces, setDecimalPlaces] = useState(0);
  const [yAxisMin, setYAxisMin] = useState<number | undefined>();
  const [yAxisMax, setYAxisMax] = useState<number | undefined>();
  const onChartHeaderBorderChange = (borderType: string) => {
    setChartHeaderBorder((prevBorder: string) =>
      prevBorder === borderType ? "" : borderType,
    );
  };

  useEffect(() => {
    onUpdateChartOptions({
      xAxisLabel: {
        formatter: getFormatterFunction(xAxisLabelFormat, decimalPlaces),
      },
      yAxisLabel: {
        formatter: getFormatterFunction(yAxisLabelFormat, decimalPlaces),
      },
      yAxis: {
        min: yAxisMin,
        max: yAxisMax,
      },
    });
  }, [
    decimalPlaces,
    xAxisLabelFormat,
    yAxisLabelFormat,
    yAxisMin,
    yAxisMax,
    onUpdateChartOptions,
  ]);

  const onXAxisLabelFormatChange = (format: string) => {
    setXAxisLabelFormat(format);
    onUpdateChartOptions({
      xAxisLabel: {
        formatter: getFormatterFunction(format, decimalPlaces),
      },
    });
  };

  const onYAxisLabelFormatChange = (format: string) => {
    setYAxisLabelFormat(format);
    onUpdateChartOptions({
      yAxisLabel: {
        formatter: getFormatterFunction(format, decimalPlaces),
      },
    });
  };

  const [openSection, setOpenSection] = useState<string>("labels");
  const [showPicker, setShowPicker] = useState(false);

  const handleOpenSection = (sectionName: string) => {
    if (openSection !== sectionName) {
      setOpenSection(sectionName);
    }
  };

  const [fetchingAnalysis, setFetchingAnalysis] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [category, setCategory] = useState<string | undefined>();
  const [subCategory, setSubCategory] = useState<string | undefined>();
  const [qualifier, setQualifier] = useState<string | undefined>();
  // eslint-disable-next-line no-unused-vars
  const [subQualifiers, setSubQualifiers] = useState<string[]>([]);
  const [element, setElement] = useState<"chart" | "table">("table");

  const handleAddingElement = (element: "chart" | "table") => {
    setElement(element);
    setFetchingAnalysis(true);
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<any>(100);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const updateContentHeight = () => {
        const viewportHeight = window.innerHeight;
        const contentRect = contentElement.getBoundingClientRect();

        // Calculate the total height of collapsed accordion items below the current item
        const accordionItems = document.querySelectorAll(
          ".cds--accordion__item",
        );
        let collapsedItemsHeight = 0;
        let foundCurrentItem = false;
        for (const item of accordionItems) {
          if (item === contentElement.closest(".cds--accordion__item")) {
            foundCurrentItem = true;
          } else if (foundCurrentItem) {
            collapsedItemsHeight += item.getBoundingClientRect().height;
          }
        }

        const newContentHeight =
          viewportHeight - contentRect.top - 50 - collapsedItemsHeight;
        setContentHeight(newContentHeight);
      };

      updateContentHeight();

      const resizeObserver = new ResizeObserver(updateContentHeight);
      resizeObserver.observe(contentElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.style.maxHeight = `${contentHeight}px`;
    }
  }, [contentHeight]);

  return (
    <div className="flex flex-col flex-1 w-full border shadow-sm overflow-hidden relative bg-white">
      {!fetchingAnalysis && (
        <>
          <div className="w-full pt-3 pb-1 pl-3 pr-3 border-b border-[#8d8d8d]">
            <div
              className="flex justify-between items-center pb-3"
              style={{ borderBottom: "1px solid #e8e8e8" }}
            >
              <div className="text-sm font-semibold">Add</div>

              <div className="flex justify-between gap-2 pr-1">
                <Button
                  kind="ghost"
                  renderIcon={CrossTab}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid #6B7280", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("table");
                  }}
                ></Button>
                <Button
                  kind="ghost"
                  renderIcon={ChartStacked}
                  hasIconOnly
                  size="sm"
                  style={{ border: "1px solid ", borderRadius: "2px" }}
                  onClick={() => {
                    handleAddingElement("chart");
                  }}
                ></Button>
              </div>
            </div>
          </div>
          <Accordion isFlush={true} className="flex flex-col flex-grow min-h-0">
            <AccordionItem
              title={<span className="text-xs">Chart Type</span>}
              open={openSection === "chartType"}
              onClick={() => handleOpenSection("chartType")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="mb-1 flex justify-between items-center">
                      <div className="text-xs font-normal">Bar</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            className={`px-2 h-full ${chartType === "bar" && !stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                            onClick={() => {
                              onChartTypeChange("bar", false);
                            }}
                          >
                            <ChartColumn size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1 flex justify-between items-center">
                      <div className="text-xs font-normal">Stacked Bar</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            className={`px-2 h-full ${chartType === "bar" && stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                            onClick={() => onChartTypeChange("bar", true)}
                          >
                            <ChartBarStacked size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1 mt-1 flex justify-between items-center">
                      <div className="text-xs font-normal">Line</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            className={`px-2 h-full ${chartType === "line" && !stacked ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                            onClick={() => onChartTypeChange("line", false)}
                          >
                            <ChartLine size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1 mt-1 flex justify-between items-center">
                      <div className="text-xs font-normal">Donut</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            className={`px-2 h-full ${chartType === "donut" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                            onClick={() => onChartTypeChange("donut", false)}
                          >
                            <ChartRing size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1 mt-1 flex justify-between items-center">
                      <div className="text-xs font-normal">Scatter</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            className={`px-2 h-full ${chartType === "scatter" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                            onClick={() => onChartTypeChange("scatter", false)}
                          >
                            <ChartScatter size={20} />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Pie</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "pie" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("pie", false)}
                    >
                      <ChartPie size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Area</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "area" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("area", false)}
                    >
                      <ChartArea size={20} />
                    </button>
                  </div>
                </div>
              </div> */}
                    {/* <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Waterfall</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "waterfall" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("waterfall")}
                    >
                      <ChartWaterfall size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Range Bar</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "range-bar" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("range-bar")}
                    >
                      <ChartColumnFloating size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Histogram</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "histogram" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("histogram")}
                    >
                      <ChartHistogram size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Box Plot</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "box-plot" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("box-plot")}
                    >
                      <BoxPlot size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-1 mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Radar</div>
                <di v className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "radar-line" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("radar-line")}
                    >
                      <ChartRadar size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-1 flex justify-between items-center">
                <div className="text-xs font-normal">Bubble</div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center border rounded h-7">
                    <button
                      className={`px-2 h-full ${chartType === "bubble" ? "bg-blue-500 text-white" : "bg-transparent text-gray-500"}`}
                      onClick={() => onChartTypeChange("bubble")}
                    >
                      <ChartBubble size={20} />
                    </button>
                  </div>
                </div>
              </div> */}
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Header</span>}
              open={openSection === "Header"}
              onClick={() => handleOpenSection("Header")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="flex flex-row justify-between items-center flex-grow mt-4 mb-4 gap-2">
                      <Input
                        type="text"
                        className="w-full"
                        id="text-input-1"
                        value={chartHeader}
                        onChange={(e) => onChartHeaderChange(e.target.value)}
                      />
                      <Switch
                        id="chart-data-label-toggle"
                        checked={showChartHeader}
                        onCheckedChange={() =>
                          setShowChartHeader(!showChartHeader)
                        }
                      />
                    </div>

                    <div className="mb-4 flex flex-row flex-grow justify-between items-center gap-1">
                      <div className="flex flex-row bg-gray-200 py-1 rounded-md w-[50%] justify-center">
                        <ToggleGroup
                          type="multiple"
                          size="sm"
                          value={chartHeaderTextStyle}
                          onValueChange={(values) => {
                            onChartHeaderTextStyleChange(values);
                          }}
                          className="bg-gray-200 rounded-md bg-muted"
                        >
                          <ToggleGroupItem
                            value="bold"
                            aria-label="Toggle bold"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextStyle.includes("bold")
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextBold
                              className={`h-4 w-4 ${
                                chartHeaderTextStyle.includes("bold")
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="italic"
                            aria-label="Toggle italic"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextStyle.includes("italic")
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextItalic
                              className={`h-4 w-4 ${
                                chartHeaderTextStyle.includes("italic")
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="underline"
                            aria-label="Toggle underline"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextStyle.includes("underline")
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextUnderline
                              className={`h-4 w-4 ${
                                chartHeaderTextStyle.includes("underline")
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                        </ToggleGroup>
                      </div>
                      <div className="flex flex-row bg-gray-200 py-1 rounded-md w-[50%] justify-center">
                        <ToggleGroup
                          type="single"
                          size="sm"
                          value={chartHeaderTextAlignment}
                          onValueChange={(value) => {
                            onChartHeaderTextAlignmentChange(value);
                          }}
                          className="bg-gray-200 rounded-md bg-muted"
                        >
                          <ToggleGroupItem
                            value="left"
                            aria-label="Align Left"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextAlignment === "left"
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextAlignLeft
                              className={`h-4 w-4 ${
                                chartHeaderTextAlignment === "left"
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="center"
                            aria-label="Align Center"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextAlignment === "center"
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextAlignCenter
                              className={`h-4 w-4 ${
                                chartHeaderTextAlignment === "center"
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="right"
                            aria-label="Align Right"
                            className={`px-2.5 py-1 ${
                              chartHeaderTextAlignment === "right"
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextAlignRight
                              className={`h-4 w-4 ${
                                chartHeaderTextAlignment === "right"
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                        </ToggleGroup>
                      </div>
                    </div>

                    <div className="mb-4 flex flex-row gap-1 items-center">
                      <Select
                        value={chartHeaderFontFamily}
                        onValueChange={onChartHeaderFontFamilyChange}
                      >
                        <SelectTrigger className="w-1/2">
                          <SelectValue placeholder="Font" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Font</SelectLabel>
                            <SelectItem value="Arial">Arial</SelectItem>
                            <SelectItem value="Calibri">Calibri</SelectItem>
                            <SelectItem value="Garamond">Garamond</SelectItem>
                            <SelectItem value="Arial Black">
                              Arial Black
                            </SelectItem>
                            <SelectItem value="Verdana">Verdana</SelectItem>
                            <SelectItem value="Tahoma">Tahoma</SelectItem>
                            <SelectItem value="Trebuchet MS">
                              Trebuchet MS
                            </SelectItem>
                            <SelectItem value="Impact">Impact</SelectItem>
                            <SelectItem value="Times New Roman">
                              Times New Roman
                            </SelectItem>
                            <SelectItem value="Didot">Didot</SelectItem>
                            <SelectItem value="Georgia">Georgia</SelectItem>
                            <SelectItem value="American Typewriter">
                              American Typewriter
                            </SelectItem>
                            <SelectItem value="Andalé Mono">
                              Andalé Mono
                            </SelectItem>
                            <SelectItem value="Courier">Courier</SelectItem>
                            <SelectItem value="Lucida Console">
                              Lucida Console
                            </SelectItem>
                            <SelectItem value="Monaco">Monaco</SelectItem>
                            <SelectItem value="Bradley Hand">
                              Bradley Hand
                            </SelectItem>
                            <SelectItem value="Brush Script MT">
                              Brush Script MT
                            </SelectItem>
                            <SelectItem value="Luminari">Luminari</SelectItem>
                            <SelectItem value="Comic Sans MS">
                              Comic Sans MS
                            </SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <Select
                        value={chartHeaderFontSize.toString()}
                        onValueChange={(value) =>
                          onChartHeaderFontSizeChange(Number(value))
                        }
                      >
                        <SelectTrigger className="w-1/3">
                          <SelectValue placeholder="Size" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Size</SelectLabel>
                            <SelectItem value="6">6</SelectItem>
                            <SelectItem value="7">7</SelectItem>
                            <SelectItem value="8">8</SelectItem>
                            <SelectItem value="9">9</SelectItem>
                            <SelectItem value="10">10</SelectItem>
                            <SelectItem value="11">11</SelectItem>
                            <SelectItem value="12">12</SelectItem>
                            <SelectItem value="14">14</SelectItem>
                            <SelectItem value="16">16</SelectItem>
                            <SelectItem value="18">18</SelectItem>
                            <SelectItem value="20">20</SelectItem>
                            <SelectItem value="22">22</SelectItem>
                            <SelectItem value="24">24</SelectItem>
                            <SelectItem value="26">26</SelectItem>
                            <SelectItem value="28">28</SelectItem>
                            <SelectItem value="30">30</SelectItem>
                          </SelectGroup>
                        </SelectContent>
                      </Select>

                      <div
                        className="h-5 w-12 rounded-full overflow-hidden cursor-pointer relative"
                        style={{
                          backgroundColor: chartHeaderTextColor,
                          border: "1px solid #dde1e6",
                        }}
                        onClick={(e) => {
                          const input = e.currentTarget.firstElementChild;
                          if (input instanceof HTMLElement) {
                            if (showPicker) {
                              setShowPicker(false);
                            } else {
                              input.click();
                              setShowPicker(true);
                            }
                          }
                        }}
                      >
                        <input
                          style={{
                            opacity: 0,
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                            transform: "scale(0.5)",
                            transformOrigin: "top left",
                          }}
                          type="color"
                          id="headerTextColor"
                          value={chartHeaderTextColor}
                          onChange={(e) =>
                            onChartHeaderTextColorChange(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-4 flex justify-between items-center">
                      <div className="text-xs font-normal">Background</div>
                      <div className="flex items-center justify-between">
                        <div
                          className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                          style={{
                            backgroundColor: chartHeaderBackgroundColor,
                            border: "1px solid #dde1e6",
                          }}
                          onClick={(e) => {
                            const input = e.currentTarget.firstElementChild;
                            if (input instanceof HTMLElement) {
                              if (showPicker) {
                                setShowPicker(false);
                              } else {
                                input.click();
                                setShowPicker(true);
                              }
                            }
                          }}
                        >
                          <input
                            style={{
                              opacity: 0,
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                              transform: "scale(0.5)",
                              transformOrigin: "top left",
                            }}
                            type="color"
                            id="headerBackgroundColor"
                            value={chartHeaderBackgroundColor}
                            onChange={(e) =>
                              onChartHeaderBackgroundColorChange(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 flex justify-between items-center">
                      <div className="text-xs font-normal">Border</div>
                      <div className="flex items-center border rounded h-5">
                        <BorderFull
                          size={20}
                          className={`ml-2 mr-4 ${chartHeaderBorder === "full" ? "text-blue-500" : "text-gray-500"}`}
                          onClick={() => onChartHeaderBorderChange("full")}
                        />
                        <BorderBottom
                          size={20}
                          className={`mr-4 ${chartHeaderBorder === "bottom" ? "text-blue-500" : "text-gray-500"}`}
                          onClick={() => onChartHeaderBorderChange("bottom")}
                        />
                        <BorderTop
                          size={20}
                          className={`mr-4 ${chartHeaderBorder === "top" ? "text-blue-500" : "text-gray-500"}`}
                          onClick={() => onChartHeaderBorderChange("top")}
                        />

                        <div
                          className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                          style={{
                            backgroundColor: chartHeaderBorderColor,
                            border: "1px solid #dde1e6",
                          }}
                          onClick={(e) => {
                            const input = e.currentTarget.firstElementChild;
                            if (input instanceof HTMLElement) {
                              if (showPicker) {
                                setShowPicker(false);
                              } else {
                                input.click();
                                setShowPicker(true);
                              }
                            }
                          }}
                        >
                          <input
                            style={{
                              opacity: 0,
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            type="color"
                            id="headerBorderColor"
                            value={chartHeaderBorderColor}
                            onChange={(e) =>
                              onChartHeaderBorderColorChange(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Axes</span>}
              open={openSection === "axisAndLabels"}
              onClick={() => handleOpenSection("axisAndLabels")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Axis Grid Lines</div>
                      <div className="flex items-center justify-between">
                        <Checkbox
                          checked={isAxisGridLines}
                          onChange={(event) =>
                            onAxisGridLinesChange(event.target.checked)
                          }
                          id="axisGridLines"
                          labelText=""
                        />
                      </div>
                    </div>

                    <div className="mt-2 mb-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Set Decimals</div>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center border rounded h-7">
                          <button
                            onClick={() =>
                              setDecimalPlaces((prev) => Math.max(0, prev - 1))
                            }
                            className={`px-2 h-full "hover:bg-blue-500 hover:text-white" : "bg-transparent text-gray-500"}`}
                          >
                            <CaretLeft size={20} />
                          </button>
                          <span className="text-xs">{decimalPlaces}</span>
                          <button
                            onClick={() => setDecimalPlaces((prev) => prev + 1)}
                            className={`px-2 h-full "hover:bg-blue-500 hover:text-white" : "bg-transparent text-gray-500"}`}
                          >
                            <CaretRight size={20} />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 mb-2 flex flex-row flex-grow justify-between items-center">
                      <Select
                        value={xAxisLabelFormat}
                        onValueChange={onXAxisLabelFormatChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Format" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>X-Axis Format</SelectLabel>
                            <SelectItem value="default" />
                            <SelectItem value="currency" />
                            <SelectItem value="currencyEUR" />
                            <SelectItem value="currencyGBP" />
                            <SelectItem value="percent" />
                            <SelectItem value="text" />
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="mt-3 mb-3 flex flex-row flex-grow justify-between items-center">
                      <Select
                        value={yAxisLabelFormat}
                        onValueChange={onYAxisLabelFormatChange}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select a font" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Y-Axis Format</SelectLabel>
                            <SelectItem value="default" />
                            <SelectItem value="currency" />
                            <SelectItem value="currencyEUR" />
                            <SelectItem value="currencyGBP" />
                            <SelectItem value="percent" />
                            <SelectItem value="text" />
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="mt-3 mb-3 flex flex-row flex-grow justify-between items-center">
                      <div className="text-xs font-normal">Y-Axis Min-Max</div>
                      <div className="flex items-center">
                        <input
                          className="max-w-10 text-xs border rounded [&_input]:border-none [&_input]:bg-transparent [&_input]:text-sm [&_input]:h-7 mr-2"
                          type="number"
                          id="y-axis-min"
                          value={yAxisMin === undefined ? "" : yAxisMin}
                          onChange={(e) =>
                            setYAxisMin(
                              e.target.value === ""
                                ? undefined
                                : Number(e.target.value),
                            )
                          }
                        />
                        <input
                          className="max-w-10 text-xs border rounded [&_input]:border-none [&_input]:bg-transparent [&_input]:text-sm [&_input]:h-7"
                          type="number"
                          id="y-axis-max"
                          value={yAxisMax === undefined ? "" : yAxisMax}
                          onChange={(e) =>
                            setYAxisMax(
                              e.target.value === ""
                                ? undefined
                                : Number(e.target.value),
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Data Labels</span>}
              open={openSection === "Data Labels"}
              onClick={() => handleOpenSection("Data Labels")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="data-label-section">
                      <div className="flex flex-row flex-grow justify-between gap-3 items-center mt-2">
                        <Select
                          value={chartDataLabelFormat}
                          onValueChange={(value) => {
                            setChartDataLabelFormat(value);
                            setChartDataLabelBackgroundColor(value); // not used here, saving variable for fuutre implementation
                            setChartDataLabelBackgroundSize(value); // not used here, saving variable for fuutre implementation
                          }}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Format" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>Format</SelectLabel>
                              <SelectItem value="general">General</SelectItem>

                              <SelectItem value="total">Currency</SelectItem>
                              <SelectItem value="percent">Percent</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        <Switch
                          id="chart-data-label-toggle"
                          checked={isChartDataLabelOn}
                          onCheckedChange={() =>
                            setIsChartDataLabelOn(!isChartDataLabelOn)
                          }
                        />
                      </div>
                      <div className="mt-4 mb-2 flex flex-row flex-grow justify-between items-center">
                        <Select
                          value={chartDataLabelFontFamily}
                          onValueChange={onChartDataLabelFontFamilyChange}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select a font" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>Font</SelectLabel>
                              <SelectItem value="Arial">Arial</SelectItem>
                              <SelectItem value="Calibri">Calibri</SelectItem>
                              <SelectItem value="Garamond">Garamond</SelectItem>
                              <SelectItem value="Arial Black">
                                Arial Black
                              </SelectItem>
                              <SelectItem value="Verdana">Verdana</SelectItem>
                              <SelectItem value="Tahoma">Tahoma</SelectItem>
                              <SelectItem value="Trebuchet MS">
                                Trebuchet MS
                              </SelectItem>
                              <SelectItem value="Impact">Impact</SelectItem>
                              <SelectItem value="Times New Roman">
                                Times New Roman
                              </SelectItem>
                              <SelectItem value="Didot">Didot</SelectItem>
                              <SelectItem value="Georgia">Georgia</SelectItem>
                              <SelectItem value="American Typewriter">
                                American Typewriter
                              </SelectItem>
                              <SelectItem value="Andalé Mono">
                                Andalé Mono
                              </SelectItem>
                              <SelectItem value="Courier">Courier</SelectItem>
                              <SelectItem value="Lucida Console">
                                Lucida Console
                              </SelectItem>
                              <SelectItem value="Monaco">Monaco</SelectItem>
                              <SelectItem value="Bradley Hand">
                                Bradley Hand
                              </SelectItem>
                              <SelectItem value="Brush Script MT">
                                Brush Script MT
                              </SelectItem>
                              <SelectItem value="Luminari">Luminari</SelectItem>
                              <SelectItem value="Comic Sans MS">
                                Comic Sans MS
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-row flex-grow justify-between gap-3 mt-2 mb-2">
                        <div className="flex items-center justify-between">
                          <div
                            className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                            style={{
                              backgroundColor: chartDataLabelBackgroundColor,
                              border: "1px solid #dde1e6",
                            }}
                            onClick={(e) => {
                              const input = e.currentTarget.firstElementChild;
                              if (input instanceof HTMLElement) {
                                input.click();
                              }
                            }}
                          >
                            <input
                              style={{
                                opacity: 0,
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              type="color"
                              value={chartDataLabelColor}
                              onChange={(e) =>
                                setChartDataLabelColor(e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="mt-2 mb-2">
                          <Select
                            value={chartDataLabelFontSize.toString()}
                            onValueChange={(value) =>
                              setChartDataLabelFontSize(Number(value))
                            }
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Size" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectGroup>
                                <SelectLabel>Size</SelectLabel>
                                <SelectItem value="6">6</SelectItem>
                                <SelectItem value="7">7</SelectItem>
                                <SelectItem value="8">8</SelectItem>
                                <SelectItem value="9">9</SelectItem>
                                <SelectItem value="10">10</SelectItem>
                                <SelectItem value="11">11</SelectItem>
                                <SelectItem value="12">12</SelectItem>
                                <SelectItem value="14">14</SelectItem>
                                <SelectItem value="16">16</SelectItem>
                                <SelectItem value="18">18</SelectItem>
                                <SelectItem value="20">20</SelectItem>
                                <SelectItem value="22">22</SelectItem>
                                <SelectItem value="24">24</SelectItem>
                                <SelectItem value="26">26</SelectItem>
                                <SelectItem value="28">28</SelectItem>
                                <SelectItem value="30">30</SelectItem>
                              </SelectGroup>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="flex w-full max-w-sm items-center space-x-2">
                          <Input
                            defaultValue="0"
                            min="0"
                            type="number"
                            value={chartDataLabelDecimalPlaces}
                            onChange={(e) =>
                              setChartDataLabelDecimalPlaces(
                                Number(e.target.value),
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="flex flex-row flex-shrink bg-gray-200 rounded-md px-1 py-0.5 justify-between bg-muted">
                        <ToggleGroup
                          type="multiple"
                          size="sm"
                          value={[
                            isChartDataLabelBold ? "bold" : "",
                            isChartDataLabelItalic ? "italic" : "",
                            isChartDataLabelUnderlined ? "underline" : "",
                          ].filter(Boolean)}
                          onValueChange={(values) => {
                            setIsChartDataLabelBold(values.includes("bold"));
                            setIsChartDataLabelItalic(
                              values.includes("italic"),
                            );
                            setIsChartDataLabelUnderlined(
                              values.includes("underline"),
                            );
                          }}
                          className="bg-gray-200 rounded-md bg-muted"
                        >
                          <ToggleGroupItem
                            value="bold"
                            aria-label="Toggle bold"
                            className={`px-2.5 py-1 ${
                              isChartDataLabelBold ? "bg-white shadow-sm" : ""
                            }`}
                          >
                            <TextBold
                              className={`h-4 w-4 ${isChartDataLabelBold ? "text-black" : "text-gray-500"}`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="italic"
                            aria-label="Toggle italic"
                            className={`px-2.5 py-1 ${
                              isChartDataLabelItalic ? "bg-white shadow-sm" : ""
                            }`}
                          >
                            <TextItalic
                              className={`h-4 w-4 ${isChartDataLabelItalic ? "text-black" : "text-gray-500"}`}
                            />
                          </ToggleGroupItem>
                          <ToggleGroupItem
                            value="underline"
                            aria-label="Toggle underline"
                            className={`px-2.5 py-1 ${
                              isChartDataLabelUnderlined
                                ? "bg-white shadow-sm"
                                : ""
                            }`}
                          >
                            <TextUnderline
                              className={`h-4 w-4 ${
                                isChartDataLabelUnderlined
                                  ? "text-black"
                                  : "text-gray-500"
                              }`}
                            />
                          </ToggleGroupItem>
                        </ToggleGroup>
                      </div>
                    </div>
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs">Chart Area</span>}
              open={openSection === "chartArea"}
              onClick={() => handleOpenSection("chartArea")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Background</div>
                      <div className="flex items-center justify-between">
                        <div
                          className="h-5 w-5 rounded-full overflow-hidden cursor-pointer relative"
                          style={{
                            backgroundColor: chartBackgroundColor,
                            border: "1px solid #dde1e6",
                          }}
                          onClick={(e) => {
                            const input = e.currentTarget.firstElementChild;
                            if (input instanceof HTMLElement) {
                              if (showPicker) {
                                setShowPicker(false);
                              } else {
                                input.click();
                                setShowPicker(true);
                              }
                            }
                          }}
                        >
                          <input
                            style={{
                              opacity: 0,
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                            type="color"
                            id="chartBackgroundColor"
                            value={chartBackgroundColor}
                            onChange={(e) =>
                              onChartBackgroundColorChange(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">
                        Background Transparent
                      </div>
                      <div className="flex items-center justify-between">
                        <Checkbox
                          checked={isChartBackgroundTransparent}
                          onChange={(event) =>
                            onChartBackgroundTransparentChange(
                              event.target.checked,
                            )
                          }
                          id="chartBackgroundTransparent"
                          labelText=""
                          className="pr-0 mr-0"
                        />
                      </div>
                    </div>
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
            <AccordionItem
              title={<span className="text-xs accordion-title">Legend</span>}
              open={openSection === "legend"}
              onClick={() => handleOpenSection("legend")}
              className="flex flex-col"
            >
              <div className="flex flex-col flex-grow overflow-hidden">
                <NoBarScroll className="flex flex-col flex-grow">
                  <div ref={contentRef} style={{ maxHeight: contentHeight }}>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Enabled</div>
                      <div className="flex items-center justify-between">
                        <Switch
                          checked={isLegendEnabled}
                          onCheckedChange={(checked) =>
                            setIsLegendEnabled(checked)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Position</div>
                      <Select
                        value={legendPosition}
                        onValueChange={setLegendPosition}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select position" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="top">Top</SelectItem>
                          <SelectItem value="right">Right</SelectItem>
                          <SelectItem value="bottom">Bottom</SelectItem>
                          <SelectItem value="left">Left</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Orientation</div>
                      <Select
                        value={legendOrientation}
                        onValueChange={setLegendOrientation}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select orientation" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="horizontal">Horizontal</SelectItem>
                          <SelectItem value="vertical">Vertical</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Spacing</div>
                      <Slider
                        value={[legendSpacing]}
                        onValueChange={(value) => setLegendSpacing(value[0])}
                        min={0}
                        max={100}
                        step={1}
                        className="w-[180px]"
                      />
                    </div>
                    <div className="mb-2 mt-2">
                      <div className="text-xs font-semibold">Legend Item</div>
                      <div className="ml-4">
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Marker Size</div>
                          <Input
                            type="number"
                            value={legendItemMarkerSize}
                            onChange={(e) =>
                              setLegendItemMarkerSize(Number(e.target.value))
                            }
                            className="w-[180px]"
                          />
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">
                            Marker Shape
                          </div>
                          <Select
                            value={legendItemMarkerShape}
                            onValueChange={setLegendItemMarkerShape}
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder="Select shape" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="circle">Circle</SelectItem>
                              <SelectItem value="cross">Cross</SelectItem>
                              <SelectItem value="diamond">Diamond</SelectItem>
                              <SelectItem value="plus">Plus</SelectItem>
                              <SelectItem value="pin">Pin</SelectItem>
                              <SelectItem value="square">Square</SelectItem>
                              <SelectItem value="star">Star</SelectItem>
                              <SelectItem value="triangle">Triangle</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">
                            Marker Padding
                          </div>
                          <Input
                            type="number"
                            value={legendItemMarkerPadding}
                            onChange={(e) =>
                              setLegendItemMarkerPadding(Number(e.target.value))
                            }
                            className="w-[180px]"
                          />
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">
                            Marker Stroke Width
                          </div>
                          <Input
                            type="number"
                            value={legendItemMarkerStrokeWidth}
                            onChange={(e) =>
                              setLegendItemMarkerStrokeWidth(
                                Number(e.target.value),
                              )
                            }
                            className="w-[180px]"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Implement when add line types (dashed etc) to legend
                    <div className="mb-2 mt-2">
                    
                      <div className="text-xs font-semibold">Legend Line</div>
                      <div className="ml-4">
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">
                            Stroke Width
                          </div>
                          <Input
                            type="number"
                            value={legendItemLineStrokeWidth}
                            onChange={(e) =>
                              setLegendItemLineStrokeWidth(
                                Number(e.target.value),
                              )
                            }
                            className="w-[180px]"
                          />
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Length</div>
                          <Input
                            type="number"
                            value={legendItemLineLength}
                            onChange={(e) =>
                              setLegendItemLineLength(Number(e.target.value))
                            }
                            className="w-[180px]"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="mb-2 mt-2">
                      <div className="text-xs font-semibold">Legend Label</div>
                      <div className="ml-4">
                        <div className="mb-2 mt-2 flex justify-between items-center"></div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Color</div>
                          <Input
                            type="color"
                            value={legendItemLabelColor}
                            onChange={(e) =>
                              setLegendItemLabelColor(e.target.value)
                            }
                            className="w-[180px]"
                          />
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Font Style</div>
                          <Select
                            value={legendItemLabelFontStyle}
                            onValueChange={setLegendItemLabelFontStyle}
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder="Select font style" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="normal">Normal</SelectItem>
                              <SelectItem value="italic">Italic</SelectItem>
                              <SelectItem value="oblique">Oblique</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Font Weight</div>
                          <Select
                            value={legendItemLabelFontWeight}
                            onValueChange={setLegendItemLabelFontWeight}
                          >
                            <SelectTrigger className="w-[180px]">
                              <SelectValue placeholder="Select font weight" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="normal">Normal</SelectItem>
                              <SelectItem value="bold">Bold</SelectItem>
                              <SelectItem value="bolder">Bolder</SelectItem>
                              <SelectItem value="lighter">Lighter</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Font Size</div>
                          <Input
                            type="number"
                            value={legendItemLabelFontSize}
                            onChange={(e) =>
                              setLegendItemLabelFontSize(Number(e.target.value))
                            }
                            className="w-[180px]"
                          />
                        </div>
                        <div className="mb-2 mt-2 flex justify-between items-center">
                          <div className="text-xs font-normal">Font Family</div>
                          <Input
                            type="text"
                            value={legendItemLabelFontFamily}
                            onChange={(e) =>
                              setLegendItemLabelFontFamily(e.target.value)
                            }
                            className="w-[180px]"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Padding X</div>
                      <Input
                        type="number"
                        value={legendItemPaddingX}
                        onChange={(e) =>
                          setLegendItemPaddingX(Number(e.target.value))
                        }
                        className="w-[180px]"
                      />
                    </div>
                    <div className="mb-2 mt-2 flex justify-between items-center">
                      <div className="text-xs font-normal">Padding Y</div>
                      <Input
                        type="number"
                        value={legendItemPaddingY}
                        onChange={(e) =>
                          setLegendItemPaddingY(Number(e.target.value))
                        }
                        className="w-[180px]"
                      />
                    </div>
                  </div>
                </NoBarScroll>
              </div>
            </AccordionItem>
          </Accordion>
        </>
      )}
      <div
        className={`absolute top-0 right-0 bottom-0 w-full flex flex-col flex-grow min-h-0 shadow-lg transition-transform duration-5000 ease-in-out transform ${
          fetchingAnalysis ? "translate-x-0" : "translate-x-full"
        }`}
        style={{ zIndex: 1 }}
      >
        <Theme theme="g100" className="flex flex-col flex-grow min-h-0">
          <div className="flex flex-col">
            <div
              className="text-sm font-semibold flex items-center gap-2"
              style={{ borderBottom: "1px solid #525252" }}
            >
              <Button
                kind="ghost"
                size="md"
                hasIconOnly
                renderIcon={RightPanelClose}
                onClick={() => setFetchingAnalysis(false)}
              />
              Analysis Selection
            </div>
          </div>

          <div className="flex flex-col flex-grow min-h-0 mt-1">
            <Tabs
              selectedIndex={selectedTabIndex}
              onChange={(e) => setSelectedTabIndex(e.selectedIndex)}
              className="flex flex-col flex-grow min-h-0"
            >
              <TabList
                aria-label="Run New Analysis Tabs"
                className="text-xs px-3 mt-3"
              >
                <Tab>Category</Tab>
                <Tab disabled={!category}>Sub-Category</Tab>
                <Tab disabled={!(category && subCategory)}>Qualifier</Tab>
              </TabList>
              <div className="px-3 flex flex-col flex-grow min-h-0 mt-3">
                <TabPanels>
                  <TabPanel className="flex flex-col flex-grow min-h-0">
                    <div className="flex flex-col flex-grow space-y-2 overflow-y-auto min-h-0 scroll-wrapper">
                      {Object.keys(analyses).map((key) => {
                        return (
                          <ClickableTile
                            key={key}
                            id={`selectable-tile-${key}`}
                            renderIcon={ArrowRight}
                            onClick={() => {
                              setCategory(key);
                              setSelectedTabIndex(1);
                            }}
                          >
                            <div>
                              <h3 className="text-xs font-semibold">{key}</h3>
                            </div>
                          </ClickableTile>
                        );
                      })}
                    </div>
                  </TabPanel>
                  <TabPanel className="flex flex-col flex-grow min-h-0">
                    <div className="flex flex-col flex-grow space-y-2 overflow-y-auto min-h-0 scroll-wrapper">
                      {category &&
                        Object.keys(analyses[category].children).map((key) => {
                          return (
                            <ClickableTile
                              key={key}
                              renderIcon={ArrowRight}
                              id={`selectable-tile-${key}`}
                              onClick={() => {
                                setSubCategory(key);
                                setSelectedTabIndex(2);
                                onSubCategoryLabelChange(key); // Set the subCategoryLabel state
                              }}
                            >
                              <div>
                                <h3 className="text-xs font-semibold">{key}</h3>
                              </div>
                            </ClickableTile>
                          );
                        })}
                    </div>
                  </TabPanel>
                  <TabPanel className="flex flex-col flex-grow min-h-0">
                    <div className="flex flex-col flex-grow space-y-2 overflow-y-auto min-h-0 scroll-wrapper">
                      {category &&
                        subCategory &&
                        analyses[category]?.children[subCategory]?.children &&
                        Object.keys(
                          analyses[category].children[subCategory].children,
                        ).map((key) => {
                          const qualifier: any =
                            analyses[category].children[subCategory].children[
                              key
                            ];
                          const hasSubQualifiers =
                            qualifier.subQualifiers &&
                            qualifier.subQualifiers.length > 0;

                          return hasSubQualifiers ? (
                            <ExpandableTile
                              key={key}
                              id={`expandable-tile-${key}`}
                              tileCollapsedIconText="Expand to select subqualifiers"
                              tileExpandedIconText="Collapse subqualifiers"
                              onClick={() => {
                                setQualifier(
                                  qualifier === key ? undefined : key,
                                );
                                onChartQualifierLabelChange(key);
                              }}
                              expanded={qualifier === key}
                            >
                              <TileAboveTheFoldContent>
                                <div className="text-xs font-semibold">
                                  {key}
                                </div>
                              </TileAboveTheFoldContent>
                              <TileBelowTheFoldContent>
                                <div className="flex justify-between items-center mb-2">
                                  <div className="text-xs mt-3 text-[#c6c6c6]">
                                    Sub-qualifiers
                                  </div>
                                  <Link
                                    className="text-xs mt-3"
                                    onClick={() => {
                                      if (
                                        subQualifiers.length ===
                                        (qualifier.subQualifiers as any).length
                                      ) {
                                        setSubQualifiers([]);
                                      } else {
                                        setSubQualifiers(
                                          qualifier.subQualifiers as any,
                                        );
                                      }
                                    }}
                                  >
                                    {subQualifiers.length ===
                                    (qualifier.subQualifiers as any).length
                                      ? "Deselect All"
                                      : "Select All"}
                                  </Link>
                                </div>
                                <div className="flex flex-col space-y-2">
                                  {qualifier.subQualifiers?.map(
                                    (subkey: any) => (
                                      <Checkbox
                                        key={`checkbox-${key}-${subkey}`}
                                        id={`checkbox-${key}-${subkey}`}
                                        labelText={subkey}
                                        checked={subQualifiers.includes(subkey)}
                                        onChange={(checked) => {
                                          if (checked) {
                                            setSubQualifiers([
                                              ...subQualifiers,
                                              subkey,
                                            ]);
                                          } else {
                                            setSubQualifiers(
                                              subQualifiers.filter(
                                                (q) => q !== subkey,
                                              ),
                                            );
                                          }
                                        }}
                                      />
                                    ),
                                  )}
                                </div>
                              </TileBelowTheFoldContent>
                            </ExpandableTile>
                          ) : (
                            <ClickableTile
                              key={key}
                              id={`selectable-tile-${key}`}
                              onClick={() => {
                                setQualifier(
                                  qualifier === key ? undefined : key,
                                );
                              }}
                            >
                              <TileAboveTheFoldContent>
                                <h3 className="text-xs font-semibold">{key}</h3>
                              </TileAboveTheFoldContent>
                            </ClickableTile>
                          );
                        })}
                    </div>
                  </TabPanel>
                </TabPanels>
              </div>
            </Tabs>

            <div className="mt-4 flex justify-between w-full">
              <Button
                kind="secondary"
                onClick={() => setFetchingAnalysis(false)}
                className="w-1/2"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (selectedTabIndex === 0 && category) {
                    setSelectedTabIndex(1);
                  } else if (selectedTabIndex === 1 && subCategory) {
                    setSelectedTabIndex(2);
                  } else if (selectedTabIndex === 2 && qualifier) {
                    setFetchingAnalysis(false);

                    if (element === "table") {
                      onTableToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers!,
                      );
                    } else {
                      onChartToggle(
                        category!,
                        subCategory!,
                        qualifier!,
                        subQualifiers!,
                      );
                    }

                    setCategory(undefined);
                    setSubCategory(undefined);

                    setQualifier(undefined);

                    setSelectedTabIndex(0);
                  }
                }}
                className="w-1/2"
              >
                Next
              </Button>
            </div>
          </div>
        </Theme>
      </div>
    </div>
  );
};
