"use client";
import { AccordionCard, ScrollWrapper, Checkbox } from "@/components";
import React, { useState } from "react";
import { RunNewAnalysisStages, StagesKeys } from "./types";
import { useRouter } from "next/navigation";
import { GetAvailableAnalysesOutput } from "../../../../../core/model/api/analysis";
import { fetchWithToken } from "@/api";
import { useAuth } from "@clerk/nextjs";
import { API_URL } from "@/constants";
import { Loading, ProgressStep } from "@carbon/react";
import { ProgressIndicator, Button } from "@carbon/react";

type Props = {
  dealId: string;
  analyses: GetAvailableAnalysesOutput;
};

export const RunNewAnalysis = ({ analyses, dealId }: Props) => {
  const router = useRouter();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const runAnalysis = () => {
    setLoading(true);
    const body = {
      category: selectedData[StagesKeys.CATEGORY]!,
      subcategory: selectedData[StagesKeys.SUB_CATEGORY]!,
      qualifier: selectedData[StagesKeys.QUALIFIER]!,
      subQualifiers: subQualifiers,
    };

    auth
      .getToken()
      .then((token) =>
        fetchWithToken(`${API_URL}/executeAnalysis/${dealId}`, token!, {
          method: "POST",
          body: JSON.stringify(body),
        }),
      )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error running analysis");
        }
        return response.json();
      })
      .then(() => {
        router.push(`/deals/${dealId}`);
      })
      .catch((error: any) => {
        console.error("Unable to execute analysis:", error);
      });
  };

  const [currentTab, setCurrentTab] = useState<
    RunNewAnalysisStages | undefined
  >();
  const [selectedData, setSelectedData] = useState<
    Record<StagesKeys, string | undefined>
  >({
    [StagesKeys.CATEGORY]: undefined,
    [StagesKeys.SUB_CATEGORY]: undefined,
    [StagesKeys.QUALIFIER]: undefined,
    // [StagesKeys.FILE]: null,
    // [StagesKeys.CONFIRM_DATA]: null,
  });
  const [subQualifiers, setSubQualifiers] = useState<string[] | undefined>();

  const tabs = [
    {
      id: RunNewAnalysisStages.SELECT_CATEGORY,
      name: "Select Category",
      label: "Category",
      key: StagesKeys.CATEGORY,
    },
    {
      id: RunNewAnalysisStages.SELECT_SUB_CATEGORY,
      name: "Select Sub-Category",
      label: "Sub-Category",
      key: StagesKeys.SUB_CATEGORY,
    },
    {
      id: RunNewAnalysisStages.SELECT_QUALIFIER,
      name: "Select Qualifier",
      label: "Qualifier",
      key: StagesKeys.QUALIFIER,
    },
    // {
    //   id: RunNewAnalysisStages.SELECT_FILE,
    //   name: "Select File",
    //   label: "File",
    //   key: StagesKeys.FILE,
    // },
    // {
    //   id: RunNewAnalysisStages.CONFIRM_DATA,
    //   key: StagesKeys.CONFIRM_DATA,
    //   name: "Confirm Data",
    // },
  ];

  const renderCheckboxes = (props: {
    cardId: string;
    options: {
      label: string;
      value: string;
    }[];
  }) => {
    const { options } = props;
    return (
      <div className="flex flex-col gap-2">
        {options.map((checkbox) => {
          return (
            <Checkbox
              key={checkbox.value}
              value={checkbox.value}
              label={checkbox.label}
              onClick={() => {
                setSubQualifiers((prevState) => {
                  if (prevState?.includes(checkbox.value)) {
                    return prevState?.filter(
                      (value) => value !== checkbox.value,
                    );
                  }
                  return [...(prevState || []), checkbox.value];
                });
              }}
              checked={(subQualifiers || []).includes(checkbox.value)}
            />
          );
        })}
      </div>
    );
  };

  const getResetedData = (stageKey: StagesKeys) => {
    const stageIndex = tabs.findIndex((tab) => tab.key === stageKey);
    return Object.fromEntries(
      tabs
        .filter((tab, index) => index >= stageIndex)
        .map((tab) => [tab.key, undefined]),
    );
  };

  const handleCardSelect =
    (
      stageKey: StagesKeys,
      value: string,
      nextStage: RunNewAnalysisStages | undefined,
      currentStage: RunNewAnalysisStages,
    ) =>
    () => {
      if (selectedData[stageKey]) {
        setSelectedData((prevState) => ({
          ...prevState,
          [stageKey]: value,
          ...getResetedData(stageKey),
        }));
        setCurrentTab(currentStage);
      } else {
        setSelectedData((prevState) => ({ ...prevState, [stageKey]: value }));
        if (nextStage) {
          setCurrentTab(nextStage);
        }
      }
    };

  const renderContent = (stageId: RunNewAnalysisStages) => {
    switch (stageId) {
      case RunNewAnalysisStages.SELECT_CATEGORY: {
        return Object.keys(analyses).map((category) => {
          return (
            <AccordionCard
              value={category}
              className="mb-2 max-w-96"
              isSelected={selectedData[StagesKeys.CATEGORY] === category}
              onClick={handleCardSelect(
                StagesKeys.CATEGORY,
                category,
                RunNewAnalysisStages.SELECT_SUB_CATEGORY,
                RunNewAnalysisStages.SELECT_CATEGORY,
              )}
              key={category}
              title={category}
              description={analyses[category].description}
            />
          );
        });
      }
      case RunNewAnalysisStages.SELECT_SUB_CATEGORY: {
        return Object.keys(
          analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children,
        ).map((subCategory) => (
          <AccordionCard
            className="mb-2 max-w-96"
            isSelected={selectedData[StagesKeys.SUB_CATEGORY] === subCategory}
            onClick={handleCardSelect(
              StagesKeys.SUB_CATEGORY,
              subCategory,
              RunNewAnalysisStages.SELECT_QUALIFIER,
              RunNewAnalysisStages.SELECT_SUB_CATEGORY,
            )}
            key={subCategory}
            title={subCategory}
            description={
              analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children[
                subCategory
              ].description
            }
          />
        ));
      }
      case RunNewAnalysisStages.SELECT_QUALIFIER: {
        return Object.keys(
          analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children[
            selectedData[StagesKeys.SUB_CATEGORY] ?? ""
          ]?.children || [],
        ).map((qualifier) => (
          <AccordionCard
            className="mb-2 max-w-96"
            onClick={handleCardSelect(
              StagesKeys.QUALIFIER,
              qualifier,
              // RunNewAnalysisStages.SELECT_FILE,
              undefined,
              RunNewAnalysisStages.SELECT_QUALIFIER,
            )}
            key={qualifier}
            title={qualifier}
            description={
              analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children[
                selectedData[StagesKeys.SUB_CATEGORY] ?? ""
              ]?.children[qualifier].description
            }
            hiddenContent={
              analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children[
                selectedData[StagesKeys.SUB_CATEGORY] ?? ""
              ].children[qualifier].subQualifiers !== undefined &&
              renderCheckboxes({
                cardId: selectedData[StagesKeys.SUB_CATEGORY] ?? "",
                options: (
                  analyses[selectedData[StagesKeys.CATEGORY] ?? ""]?.children[
                    selectedData[StagesKeys.SUB_CATEGORY] ?? ""
                  ].children[qualifier].subQualifiers || []
                ).map((subQualifier) => ({
                  label: subQualifier,
                  value: subQualifier,
                })),
              })
            }
          />
        ));
      }
    }
  };
  return (
    <>
      <ProgressIndicator spaceEqually>
        <ProgressStep
          label="Select Category"
          complete={selectedData[StagesKeys.CATEGORY] !== undefined}
          current={currentTab === RunNewAnalysisStages.SELECT_CATEGORY}
        />
        <ProgressStep
          label="Select Sub-Category"
          complete={selectedData[StagesKeys.SUB_CATEGORY] !== undefined}
          current={currentTab === RunNewAnalysisStages.SELECT_SUB_CATEGORY}
        />
        <ProgressStep
          label="Select Qualifier"
          complete={selectedData[StagesKeys.QUALIFIER] !== undefined}
          current={currentTab === RunNewAnalysisStages.SELECT_QUALIFIER}
        />
      </ProgressIndicator>
      <ScrollWrapper withFade className="mb-18">
        <div className="flex flex-col gap-11 min-h-0 pb-16">
          {tabs.map((tab, index) => {
            const { key, name, id } = tab;
            const stageData = key && selectedData[key];
            const prevStageKey = index === 0 ? undefined : tabs[index - 1].key;
            if (prevStageKey && !selectedData[prevStageKey]) {
              return;
            }
            return (
              <div
                key={tab.name}
                className={`${stageData || currentTab === id ? "text-Grey100" : "text-Grey50"}`}
              >
                <h3 className="text-3xl font-bold text-DarkBlue">
                  {index + 1}. {name}
                </h3>
                <div className="grid grid-cols-3 md:grid-cols-4 gap-3 flex-wrap mt-4">
                  {renderContent(tab.id)}
                </div>
              </div>
            );
          })}
        </div>
      </ScrollWrapper>
      <div className="bottom-0 right-0 p-4 px-10 border-t-2 border-Gray100">
        <Button
          isFullWidth
          disabled={!Object.values(selectedData).every(Boolean)}
          className="float-right max-w-80 bottom-0 right-0"
          onClick={runAnalysis}
        >
          {loading ? (
            <div className="flex justify-center items-center gap-2">
              <span>Running Analysis</span>
              <Loading withOverlay={false} small className="bg-White" />
            </div>
          ) : (
            "Run Analysis"
          )}
        </Button>
      </div>
    </>
  );
};
