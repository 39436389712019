import React from "react";
export const Chart = () => {
  return (
    <div className="text-DarkBlue">
      <h3 className="text-xl font-medium">Title Example</h3>
      <div className="h-10 flex justify-around">
        <div className="flex items-end gap-2">
          <div className="w-8 h-1/4 bg-LightBlue rounded"></div>
          <div className="w-8 h-2/3 bg-DarkBlue rounded"></div>
        </div>
        <div className="flex items-end gap-2">
          <div className="w-8 h-1/4 bg-LightBlue rounded"></div>
          <div className="w-8 h-2/3 bg-DarkBlue rounded"></div>
        </div>
        <div className="flex items-end gap-2">
          <div className="w-8 h-2/3 bg-LightBlue rounded"></div>
          <div className="w-8 h-2/3 bg-DarkBlue rounded"></div>
        </div>
        <div className="flex items-end gap-2">
          <div className="w-8 h-1/4 bg-LightBlue rounded"></div>
          <div className="w-8 h-1/4 bg-DarkBlue rounded"></div>
        </div>
      </div>
    </div>
  );
};
