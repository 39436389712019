import * as React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
/* eslint-disable import/no-internal-modules */
import { cn } from "@/lib/utils";

interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const NoBarScroll = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ className, children, ...props }, ref) => {
    const [showUpButton, setShowUpButton] = React.useState(false);
    const [showDownButton, setShowDownButton] = React.useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);

    const handleScroll = () => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        setShowUpButton(scrollTop > 0);
        setShowDownButton(scrollTop + clientHeight < scrollHeight);
      }
    };

    React.useEffect(() => {
      const timer = setTimeout(() => {
        if (contentRef.current) {
          const { scrollHeight, clientHeight } = contentRef.current;
          setShowDownButton(scrollHeight > clientHeight);
        }
      }, 200);

      return () => clearTimeout(timer);
    }, []);

    return (
      <div
        ref={ref}
        className={cn("relative overflow-hidden", className)}
        {...props}
      >
        <div
          ref={contentRef}
          className="overflow-y-auto py-1 hide-scrollbar"
          onScroll={handleScroll}
        >
          {children}
        </div>
        {showUpButton && <ScrollUpButton />}
        <div className="transition-opacity duration-300 ease-in-out">
          {showDownButton && <ScrollDownButton />}
        </div>
      </div>
    );
  },
);
NoBarScroll.displayName = "NoBarScroll";

const ScrollUpButton = React.forwardRef<
  React.ElementRef<"button">,
  React.ComponentPropsWithoutRef<"button">
>(({ className, ...props }, ref) => (
  <button
    ref={ref}
    className={cn(
      "absolute top-0 left-0 right-0 flex cursor-default items-center justify-center py-1 bg-white",
      className,
    )}
    {...props}
  >
    <ChevronUpIcon />
  </button>
));
ScrollUpButton.displayName = "ScrollUpButton";

const ScrollDownButton = React.forwardRef<
  React.ElementRef<"button">,
  React.ComponentPropsWithoutRef<"button">
>(({ className, ...props }, ref) => (
  <button
    ref={ref}
    className={cn(
      "absolute bottom-0 left-0 right-0 flex cursor-default items-center justify-center py-1 bg-white",
      className,
    )}
    {...props}
  >
    <ChevronDownIcon />
  </button>
));
ScrollDownButton.displayName = "ScrollDownButton";

export { NoBarScroll };
