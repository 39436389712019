"use client";

import React from "react";
import { domToPng } from "modern-screenshot";
import { Dimensions } from "@/stores";
import Image from "next/image";

interface PptExportProps {
  gridDimensions: Dimensions[];
  chartDimensions: Dimensions[];
  params: {
    dealId: string;
  };
  selectedAspectRatio: any;
  aspectRatioContainerWidth: any;
}

export const slideMasterDisplay = (
  <div
    style={{
      position: "relative",
      width: "100%",
      height: "100%",
    }}
  >
    <Image
      src="/templates/saw_mill_template.svg"
      alt="PowerPoint Template"
      layout="fill"
      objectFit="contain"
      quality={100}
    />
  </div>
);

export const exportToPowerPoint = async (
  token: string,
  { chartDimensions, gridDimensions, params }: PptExportProps,
) => {
  if (typeof window !== "undefined") {
    let containerWidth = 0;
    let containerHeight = 0;
    let scaleX = 3;

    const container = document.querySelector(".aspect-ratio-container") as any;
    containerWidth = container.clientWidth;
    containerHeight = container.clientHeight;

    console.log(
      "Container Width:",
      containerWidth,
      "Container Height:",
      containerHeight,
    );

    // Sets default domtopng DPI of 300
    scaleX = 3;

    const elements = new Map<string, any>();

    const gridElements = document.querySelectorAll(".grid-parent-div");
    for (const element of gridElements) {
      const id = element.id;
      const numId = Number.parseInt(id.split("-")[1]);
      const png = await domToPng(element);

      elements.set(`grid-${id}`, {
        ...gridDimensions[numId],
        screenshot: png,
        quality: 1,
        scale: scaleX,
        xOffset: gridDimensions[numId].exportXOffset,
        yOffset: gridDimensions[numId].exportYOffset,
        width: gridDimensions[numId].exportWidth,
        height: gridDimensions[numId].exportHeight,
        id: `grid-${id}`,
      });
    }

    const chartElements = document.querySelectorAll(".chart-parent-div");
    for (const element of chartElements) {
      const id = element.id;
      const numId = Number.parseInt(id.split("-")[1]);
      const png = await domToPng(element);
      elements.set(`chart-${id}`, {
        ...chartDimensions[numId],
        screenshot: png,
        quality: 1,
        dpi: 300,
        xOffset: chartDimensions[numId].exportXOffset,
        yOffset: chartDimensions[numId].exportYOffset,
        width: chartDimensions[numId].exportWidth,
        height: chartDimensions[numId].exportHeight,
        id: `chart-${id}`,
      });
      console.log("PNG", png);
    }

    const response = await fetch(
      `/api/${params.dealId}/activeExport/generate-pptx`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          gridElements: [...elements.values()].filter((e) =>
            e.id.startsWith("grid-"),
          ),
          chartElements: [...elements.values()].filter((e) =>
            e.id.startsWith("chart-"),
          ),
          screenSize: {
            width: containerWidth,
            height: containerHeight,
          },
        }),
      },
    );

    return response.json();
  }
};
