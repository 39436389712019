"use client";
import React, { useEffect, useState } from "react";
/* eslint-disable import/no-internal-modules */
import {
  Forum,
  Notification,
  Phone,
  UserAvatar,
  Switcher,
  Template,
  Document,
  ChartColumn,
  Settings,
} from "@carbon/react/icons";
/* eslint-enable import/no-internal-modules */
import { usePathname } from "next/navigation";
import {
  Header,
  HeaderContainer,
  HeaderMenuButton,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SideNav,
  SideNavItems,
  SideNavLink,
  Popover,
  PopoverContent,
  PopoverAlignment,
  Link,
} from "@carbon/react";
import { useUser } from "@clerk/nextjs";
import LogRocket from "logrocket";
import { API_URL } from "@/constants";

export const TransactedSidebar = () => {
  const pathname = usePathname();
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <SideNav
      className="g100-theme-class h-full"
      aria-label="Side navigation"
      expanded={isSideNavExpanded}
      onOverlayClick={onClickSideNavExpand}
      href="#main-content"
      onSideNavBlur={onClickSideNavExpand}
      isRail
    >
      <SideNavItems className="g100-theme-class">
        <SideNavLink
          renderIcon={Document}
          href="/deals"
          isActive={pathname.includes("/deals")}
        >
          Deals
        </SideNavLink>
        <SideNavLink
          renderIcon={ChartColumn}
          href="/analytics"
          isActive={pathname.includes("/analytics")}
        >
          Analytics (coming soon)
        </SideNavLink>
        <SideNavLink
          renderIcon={Template}
          href="/templates"
          isActive={pathname.includes("/templates")}
        >
          Templates (coming soon)
        </SideNavLink>
      </SideNavItems>
    </SideNav>
  );
};

export const TransactedHeader = () => {
  const { user } = useUser();
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  useEffect(() => {
    if (user?.id) {
      if (API_URL !== "http://localhost:3001") {
        LogRocket.identify(user.id, {
          name: user.fullName || "",
          email: user.emailAddresses[0]?.emailAddress || "",
        });
      }

      (window as any).intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "r8felkaf",
        custom_launcher_selector: "#intercom_chat_button",
        name: user.fullName || "",
        //user_id: user.id,
        email: user.emailAddresses[0]?.emailAddress || "",
        //created_at: user.createdAt,
      };

      // Initialize Intercom
      (function () {
        var w = window;
        var ic = (w as any).Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", (w as any).intercomSettings);
        } else {
          var d = document;
          var i = function () {
            (i as any).c(arguments);
          };
          (i as any).q = [];
          (i as any).c = function (args: any) {
            (i as any).q.push(args);
          };
          (w as any).Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/r8felkaf";
            var x = d.querySelectorAll("script")[0];
            if (x.parentNode) {
              x.parentNode.insertBefore(s, x);
            }
          };
          if (document.readyState === "complete") {
            l();
          } else if ((w as any).attachEvent) {
            (w as any).attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }, [user]);

  const [open, setOpen] = useState(false);
  const align: PopoverAlignment = "right";

  const sendPage = async () => {
    await fetch("/api/page", {
      method: "POST",
      body: JSON.stringify({
        userFullName: user?.fullName || "",
        userEmail: user?.emailAddresses[0]?.emailAddress || "",
      }),
    });
  };

  return (
    <Header
      aria-label="Transacted Diligence"
      className="g100-theme-class w-full"
    >
      <HeaderMenuButton
        aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
        onClick={onClickSideNavExpand}
        isActive={isSideNavExpanded}
        aria-expanded={isSideNavExpanded}
        isCollapsible={true}
        className="g100-theme-class"
      />
      <HeaderName
        href="/"
        prefix="Transacted"
        className="g100-theme-class text-xs"
      >
        Diligence
      </HeaderName>
      <HeaderGlobalBar className="g100-theme-class">
        {/* Placeholder for future additions */}
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="Phone Support"
        >
          <div
            className="popover-tabtip-story"
            style={{
              display: "flex",
            }}
          >
            <Popover
              align={align}
              open={open}
              onKeyDown={(evt: any) => {
                if ((evt as any).key === "Escape") {
                  setOpen(false);
                }
              }}
              isTabTip
              onRequestClose={() => setOpen(false)}
            >
              <Phone
                aria-label="Settings"
                type="button"
                aria-expanded={open}
                onClick={() => {
                  setOpen(!open);
                }}
              />

              <PopoverContent className="mt-2 p-3 w-40">
                <div className="text-xs mt-5 mb-5">
                  Please call <Link>(585) 773-0030</Link> for 24/7 support.
                </div>
                <hr />
                <div className="text-xs mt-5 mb-5">
                  Submit a pager alert for after hours support or on
                  non-response.
                </div>
                <Link onClick={sendPage}>Send support alert</Link>
              </PopoverContent>
            </Popover>
          </div>
        </HeaderGlobalAction>
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="Chat Support"
          id={"intercom_chat_button" as any}
          onClick={() => {}}
          {...({ id: "intercom_chat_button" } as any)}
        >
          <Forum fill="white" />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="Notifications"
          onClick={() => {}}
        >
          <Notification fill="white" />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="Settings"
          onClick={() => {}}
        >
          <Settings fill="white" />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="User"
          onClick={() => {}}
        >
          <UserAvatar />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          className="g100-theme-class"
          aria-label="App Switcher"
          onClick={() => {}}
          tooltipAlignment="end"
        >
          <Switcher />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
};

export const CollapsedSidebar = () => {
  const pathname = usePathname();
  return (
    <SideNav
      isFixedNav={true}
      expanded={true}
      isChildOfHeader={true}
      aria-label="Side navigation"
      className="g100-theme-class collapsed-sidebar"
    >
      <SideNavItems className="g100-theme-class">
        <SideNavLink
          renderIcon={Document}
          href="/deals"
          isActive={pathname.includes("/deals")}
        >
          Deals
        </SideNavLink>

        {/*  TBU - placeholders for future additions
        <SideNavLink
          renderIcon={ChartColumn}
          href="/analytics"
          isActive={pathname.includes("/analytics")}
        >
          Analytics
        </SideNavLink>
        <SideNavLink
          renderIcon={Template}
          href="/templates"
          isActive={pathname.includes("/templates")}
        >
          Templates
        </SideNavLink> */}
      </SideNavItems>
    </SideNav>
  );
};

export const Sidebar = () => {
  const pathname = usePathname();
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

  const onClickSideNavExpand = () => {
    setIsSideNavExpanded(!isSideNavExpanded);
  };

  return (
    <HeaderContainer
      render={() => (
        <>
          <Header
            aria-label="Transacted Diligence"
            className="g100-theme-class"
          >
            <HeaderMenuButton
              aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
              aria-expanded={isSideNavExpanded}
              isCollapsible={true}
              className="g100-theme-class"
            />
            <HeaderName
              href="/"
              prefix="Transacted"
              className="g100-theme-class"
            >
              Diligence
            </HeaderName>
            <HeaderGlobalBar className="g100-theme-class">
              {/* Placeholder for future additions */}
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="Phone Support"
                onClick={() => {}}
              >
                <Phone fill="white" />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="Chat Support"
                onClick={() => {}}
              >
                <Forum fill="white" />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="Chat Support"
                onClick={() => {}}
              >
                <Notification fill="white" />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="Settings"
                onClick={() => {}}
              >
                <Settings fill="white" />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="User"
                onClick={() => {}}
              >
                <UserAvatar />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                className="g100-theme-class"
                aria-label="App Switcher"
                onClick={() => {}}
                tooltipAlignment="end"
              >
                <Switcher />
              </HeaderGlobalAction>
            </HeaderGlobalBar>

            {!isSideNavExpanded && <CollapsedSidebar />}
            <SideNav
              className="g100-theme-class"
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              onSideNavBlur={onClickSideNavExpand}
              isPersistent={false}
              href="#main-content"
            >
              <SideNavItems className="g100-theme-class">
                <SideNavLink
                  renderIcon={Document}
                  href="/deals"
                  isActive={pathname.includes("/deals")}
                >
                  Deals
                </SideNavLink>
                {/* <SideNavLink
                  renderIcon={ChartColumn}
                  href="/analytics"
                  isActive={pathname.includes("/analytics")}
                >
                  Analytics
                </SideNavLink>
                <SideNavLink
                  renderIcon={Template}
                  href="/templates"
                  isActive={pathname.includes("/templates")}
                >
                  Templates
                </SideNavLink> */}
              </SideNavItems>
            </SideNav>
          </Header>
        </>
      )}
    />
  );
};
