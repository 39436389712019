import { Icons } from "@/components";
import { AgChartsReact } from "ag-charts-react";
import { ChartRenderDateOptions } from "./dateSort";
import { useState } from "react";
import { ChartOptions, DateSort } from "./types";
import { ParsedAnalysisData } from "../../../../../core/model";
import { Filter } from "../../../../../core/model/api/activeExport";
import { Chart as ChartType } from "@/stores";
import { TextAlign } from "ag-charts-community";
import { DataUnits } from "./types";
/* eslint-disable import/no-internal-modules */
import { getFormatterFunction } from "./components/OutputEditor/ChartEditor";

export function filterData(
  analysis: ParsedAnalysisData,
  dateSort: DateSort,
  filter: Filter,
  // eslint-disable-next-line no-unused-vars
  chartDataUnits: DataUnits,
) {
  return analysis[dateSort].columns.filter((column, index) => {
    if (index === 0) {
      return true; // Always include the first column (category column)
    }

    const date = new Date(column.split(" - ")[1].trim());
    return date >= filter.start && date <= filter.end;
  });
}

function getConversionFactor(dataUnits: DataUnits): number {
  switch (dataUnits) {
    case DataUnits.ACTUAL: {
      return 1;
    }
    case DataUnits.THOUSANDS: {
      return 1000;
    }
    case DataUnits.MILLIONS: {
      return 1_000_000;
    }
    case DataUnits.BILLIONS: {
      return 1_000_000_000;
    }
    default: {
      return 1;
    }
  }
}

function analysisToChartData(
  analysisData: ParsedAnalysisData,
  chartDateSort: DateSort,
  filter: Filter,
  chartType: string,
  selectedTimePeriod: any,
  chartDataUnits: DataUnits,
  stacked: boolean,
  chartQualifierLabel: string,
  subCategoryLabel: string,
  isChartDataLabelOn: boolean,
  isChartDataLabelUnderlined: boolean,
  chartDataLabelColor: string,
  isChartDataLabelItalic: boolean,
  isChartDataLabelBold: boolean,
  chartDataLabelFontSize: number,
  chartDataLabelFontFamily: string,
  chartDataLabelBackgroundColor: string,
  chartDataLabelBackgroundSize: number,
  chartDataLabelFormat: string,
  chartDataLabelDecimalPlaces: number,
): { series: Series[]; data: Record<string, number | string>[] } {
  const conversionFactor = getConversionFactor(chartDataUnits);

  if (analysisData[chartDateSort].data.length === 0) {
    // If the data array is empty, create a series using the total values
    const totalData = analysisData[chartDateSort].columns
      .slice(1)
      .map((column, index) => {
        const columnParts = column.split(" - ");
        if (columnParts.length === 2) {
          const dateString = columnParts[1].trim();
          const date = new Date(dateString);
          if (date >= filter.start && date <= filter.end) {
            const year = date.getFullYear();
            const value = analysisData[chartDateSort].total[index + 1];

            return {
              timePeriod: year,
              value:
                typeof value === "number" ? value / conversionFactor : value,
            };
          }
        }
        // eslint-disable-next-line unicorn/no-null
        return null;
      })
      .filter(
        (item): item is { timePeriod: number; value: number | string } =>
          item !== null,
      );

    const seriesName = `Total ${subCategoryLabel}`;

    const series: Series[] = [
      {
        type: chartType,
        xKey: "timePeriod",
        yKey: "value",
        calloutLabelKey: undefined,
        angleKey: undefined,
        innerRadiusRatio: undefined,
        stacked: stacked,
        yName: seriesName,
        label: {
          enabled: isChartDataLabelOn,
          textDecoration: isChartDataLabelUnderlined ? "underline" : "none",
          color: chartDataLabelColor,
          fontStyle: isChartDataLabelItalic ? "italic" : "normal",
          fontWeight: isChartDataLabelBold ? "bold" : "normal",
          fontSize: chartDataLabelFontSize,
          fontFamily: chartDataLabelFontFamily,
          padding: chartDataLabelBackgroundSize,
          formatter: getFormatterFunction(
            chartDataLabelFormat,
            chartDataLabelDecimalPlaces,
          ),
        },
      },
    ];

    return { series, data: totalData };
  }

  // Apply the series limiter functionality to the raw data
  const rawData = analysisData[chartDateSort].data;
  const categoryTotals: Record<any, any> = {};

  for (const row of rawData) {
    const category = row[0];
    const values = row.slice(1);
    const categoryTotal = values.reduce(
      (sum, value) => sum + (value as any),
      0,
    );
    categoryTotals[category] = categoryTotal;
  }

  const sortedCategories = Object.keys(categoryTotals).sort(
    (a, b) => categoryTotals[b] - categoryTotals[a],
  );

  const limitedCategories = sortedCategories.slice(0, filter.rowCount);
  const otherCategories = sortedCategories.slice(filter.rowCount);

  const transformedData: Record<string, number | string>[] = [];

  for (const timePeriod of Object.keys(analysisData)) {
    if (timePeriod === chartDateSort) {
      const timePeriodData = analysisData[timePeriod].data;
      for (const d of timePeriodData) {
        const category = d[0];
        const values = d.slice(1);
        for (const [index, value] of values.entries()) {
          const column = analysisData[timePeriod].columns[index + 1];
          const [_, dateString] = column.split(" - ");

          const date = new Date(dateString);
          // Filter based on the selected date range and period
          const year = date.getFullYear();
          const monthName = date.toLocaleString("default", {
            month: "long",
          });
          const quarter = `Q${Math.floor((new Date(date).getMonth() + 3) / 3)}`;

          if (date >= filter.start && date <= filter.end) {
            const timePeriodLabel =
              chartDateSort === DateSort.MONTHLY
                ? `${monthName} ${year}`
                : chartDateSort === DateSort.QUARTERLY
                  ? `${quarter} ${year}`
                  : year;

            const existingData = transformedData.find(
              (item) => item.timePeriod === timePeriodLabel,
            );

            if (existingData) {
              if (limitedCategories.includes(category)) {
                existingData[category] =
                  ((existingData[category] as number) || 0) + (value as number);
              } else {
                existingData["All Other"] =
                  ((existingData["All Other"] as number) || 0) +
                  (value as number);
              }
            } else {
              const newData: Record<string, number | string> = {
                timePeriod: timePeriodLabel,
              };
              if (limitedCategories.includes(category)) {
                newData[category] = value as number;
              } else {
                newData["All Other"] = value as number;
              }
              transformedData.push(newData);
            }
          }
        }
      }
    }
  }

  if ((chartType === "pie" || chartType === "donut") && !selectedTimePeriod) {
    const latestTimePeriod = transformedData.at(-1)?.timePeriod;
    // eslint-disable-next-line unicorn/prefer-array-find
    const filteredData = transformedData.filter(
      (data) => data.timePeriod === latestTimePeriod,
    );

    const donutData = limitedCategories
      .map((category) => {
        const categoryData = filteredData[0][category];
        // eslint-disable-next-line unicorn/no-negated-condition
        return categoryData !== undefined
          ? { category, value: categoryData }
          : undefined;
      })
      .filter(
        (item): item is { category: string; value: number | string } =>
          item !== undefined,
      );

    if (otherCategories.length > 0) {
      const otherData = filteredData[0]["All Other"];
      if (otherData !== undefined) {
        donutData.push({ category: "All Other", value: otherData });
      }
    }

    const series: Series[] = [
      {
        type: chartType,
        xKey: "category",
        yKey: "value",
        angleKey: "value",
        calloutLabelKey: "calloutLabel",
        calloutLabel: {
          enabled: isChartDataLabelOn,
          // offset: 10, // Adjust the offset as needed
          // minAngle: 20, // Adjust the minimum angle as needed
          // avoidCollisions: true,
          // textDecoration: isChartDataLabelUnderlined ? "underline" : "none",
          // color: chartDataLabelColor,
          // fontStyle: isChartDataLabelItalic ? "italic" : "normal",
          // fontWeight: isChartDataLabelBold ? "bold" : "normal",
          // fontSize: chartDataLabelFontSize,
          // fontFamily: chartDataLabelFontFamily,
          // padding: chartDataLabelBackgroundSize,
        },
        innerRadiusRatio: chartType === "donut" ? 0.6 : 0,
      },
    ];

    // Calculate the total value
    const total = donutData.reduce((sum, item) => sum + Number(item.value), 0);

    // Modify the donutData to include the calculated percent value
    const modifiedDonutData = donutData.map((item) => {
      const percent = ((Number(item.value) / total) * 100).toFixed(
        chartDataLabelDecimalPlaces,
      );
      return {
        ...item,
        calloutLabel: `${item.category} (${percent}%)`,
      };
    });

    return { series, data: modifiedDonutData };
  }

  const series = limitedCategories.map((category) => ({
    type: chartType,
    xKey: "timePeriod",
    yKey: category,
    calloutLabelKey: category,
    angleKey: "value",
    innerRadiusRatio: 0.6,
    stacked: stacked,
    label: {
      enabled: isChartDataLabelOn,
      textDecoration: isChartDataLabelUnderlined ? "underline" : "none",
      color: chartDataLabelColor,
      fontStyle: isChartDataLabelItalic ? "italic" : "normal",
      fontWeight: isChartDataLabelBold ? "bold" : "normal",
      fontSize: chartDataLabelFontSize,
      fontFamily: chartDataLabelFontFamily,
      padding: chartDataLabelBackgroundSize,
      formatter: getFormatterFunction(
        chartDataLabelFormat,
        chartDataLabelDecimalPlaces,
      ),
    },
  }));

  if (otherCategories.length > 0) {
    series.push({
      type: chartType,
      xKey: "timePeriod",
      yKey: "All Other",
      calloutLabelKey: "All Other",
      angleKey: "value",
      innerRadiusRatio: 0.6,
      stacked: stacked,
      label: {
        enabled: isChartDataLabelOn,
        textDecoration: isChartDataLabelUnderlined ? "underline" : "none",
        color: chartDataLabelColor,
        fontStyle: isChartDataLabelItalic ? "italic" : "normal",
        fontWeight: isChartDataLabelBold ? "bold" : "normal",
        fontSize: chartDataLabelFontSize,
        fontFamily: chartDataLabelFontFamily,
        padding: chartDataLabelBackgroundSize,
        formatter: getFormatterFunction(
          chartDataLabelFormat,
          chartDataLabelDecimalPlaces,
        ),
      },
    });
  }

  const chartData = transformedData.map((item) => {
    const convertedItem: Record<string, number | string> = {
      timePeriod: item.timePeriod as string,
    };
    for (const key of Object.keys(item)) {
      if (key !== "timePeriod") {
        convertedItem[key] = (item[key] as number) / conversionFactor;
      }
    }
    return convertedItem;
  });

  return { series, data: chartData };
}

interface Series {
  type: any;
  xKey: string;
  yKey: string;
  [key: string]: any;
  innerRadiusRatio: any;
  calloutLabelKey: any;
  angleKey: any;
}

type Props = {
  showChartHeader: boolean;
  setShowChartHeader: (show: boolean) => void;
  className: string;
  onMouseDown: (e: any) => void;
  chartDataUnits: DataUnits;
  setChartDataUnits: (value: DataUnits) => void;
  onClick: (e: any) => void;
  // Data labels
  isChartDataLabelOn: boolean;
  setIsChartDataLabelOn: (isOn: boolean) => void;
  chartDataLabelDecimalPlaces: number;
  setChartDataLabelDecimalPlaces: (decimalPlaces: number) => void;
  isChartDataLabelUnderlined: boolean;
  setIsChartDataLabelUnderlined: (isUnderlined: boolean) => void;
  isChartDataLabelItalic: boolean;
  setIsChartDataLabelItalic: (isItalic: boolean) => void;
  isChartDataLabelBold: boolean;
  setIsChartDataLabelBold: (isBold: boolean) => void;
  chartDataLabelColor: string;
  setChartDataLabelColor: (color: string) => void;
  chartDataLabelFontSize: number;
  setChartDataLabelFontSize: (fontSize: number) => void;
  chartDataLabelFontFamily: string;
  onChartDataLabelFontFamilyChange: (fontFamily: string) => void;
  chartDataLabelBackgroundColor: string;
  setChartDataLabelBackgroundColor: (color: string) => void;
  chartDataLabelBackgroundSize: number;
  setChartDataLabelBackgroundSize: (size: number) => void;
  chartDataLabelFormat: string;
  setChartDataLabelFormat: (format: string) => void;
  decimalPlaces: number;
  setDecimalPlaces: (decimalPlaces: number) => void;
  // Header
  chartHeader: string;
  onChartHeaderChange: (header: string) => void;
  chartHeaderBackgroundColor: string;
  onChartHeaderBackgroundColorChange: (color: string) => void;
  chartHeaderTextColor: string;
  onChartHeaderTextColorChange: (color: string) => void;
  chartHeaderFontFamily: string;
  onChartHeaderFontFamilyChange: (fontFamily: string) => void;
  chartHeaderFontSize: number;
  onChartHeaderFontSizeChange: (fontSize: number) => void;
  chartHeaderTextStyle: any;
  onChartHeaderTextStyleChange: (textStyle: any) => void;
  chartHeaderTextAlignment: string;
  onChartHeaderTextAlignmentChange: (textAlignment: string) => void;
  chartHeaderBorder: any;
  onChartHeaderBorderChange: (border: any) => void;
  chartHeaderBorderColor: any;
  onChartHeaderBorderColorChange: (color: any) => void;
  style: any;
  chartWrapperRef: any;
  active: boolean;
  index: number;
  chartQualifierLabel: string;
  onChartQualifierLabelChange: (label: string) => void;
  subCategoryLabel: string;
  onSubCategoryLabelChange: (label: string) => void;

  data: ParsedAnalysisData;
  chartOptions: ChartOptions;

  chartType: string;
  stacked: boolean;

  chart: ChartType;
  updateChart: (chart: ChartType) => void;

  //Chart legend
  isLegendEnabled: boolean;
  setIsLegendEnabled: (enabled: boolean) => void;
  legendPosition: any;
  setLegendPosition: (position: any) => void;
  legendOrientation: any;
  setLegendOrientation: (orientation: any) => void;
  legendSpacing: number;
  setLegendSpacing: (spacing: number) => void;

  legendItemMarkerSize: number;
  setLegendItemMarkerSize: (size: number) => void;
  legendItemMarkerShape: any;
  setLegendItemMarkerShape: (shape: any) => void;
  legendItemMarkerPadding: number;
  setLegendItemMarkerPadding: (padding: number) => void;
  legendItemMarkerStrokeWidth: number;
  setLegendItemMarkerStrokeWidth: (strokeWidth: number) => void;

  legendItemLineStrokeWidth: number;
  setLegendItemLineStrokeWidth: (strokeWidth: number) => void;
  legendItemLineLength: number;
  setLegendItemLineLength: (length: number) => void;

  legendItemLabelColor: string;
  setLegendItemLabelColor: (color: string) => void;
  legendItemLabelFontStyle: any;
  setLegendItemLabelFontStyle: (fontStyle: any) => void;
  legendItemLabelFontWeight: any;
  setLegendItemLabelFontWeight: (fontWeight: any) => void;
  legendItemLabelFontSize: number;
  setLegendItemLabelFontSize: (fontSize: number) => void;
  legendItemLabelFontFamily: string;
  setLegendItemLabelFontFamily: (fontFamily: string) => void;

  legendItemPaddingX: number;
  setLegendItemPaddingX: (paddingX: number) => void;
  legendItemPaddingY: number;
  setLegendItemPaddingY: (paddingY: number) => void;
};

export const Chart = ({
  chartDataUnits,
  setChartDataUnits,
  chartQualifierLabel,
  chartDataLabelDecimalPlaces,

  subCategoryLabel,

  // Header
  showChartHeader,
  chartHeader,
  chartHeaderBorder,
  chartHeaderBorderColor,
  chartHeaderBackgroundColor,
  chartHeaderTextColor,
  chartHeaderFontFamily,
  chartHeaderFontSize,
  chartHeaderTextStyle,
  chartHeaderTextAlignment,
  chartWrapperRef,
  active,
  data,
  chartOptions,
  onClick,
  onMouseDown,
  index,
  // x,
  // y,

  chartType,
  chart,
  stacked,
  isChartDataLabelOn,

  isChartDataLabelUnderlined,

  isChartDataLabelItalic,

  isChartDataLabelBold,

  chartDataLabelColor,

  chartDataLabelFontSize,

  chartDataLabelFontFamily,

  chartDataLabelBackgroundColor,

  chartDataLabelBackgroundSize,

  chartDataLabelFormat,

  //Chart legend

  updateChart,
}: Props) => {
  const [chartDateSort, setChartDateSort] = useState<DateSort>(DateSort.ANNUAL);
  const [selectedTimePeriod] = useState<string | undefined>();

  const { series, data: chartData } = analysisToChartData(
    data,
    chartDateSort,
    chart.filter,
    chartType,
    selectedTimePeriod,
    chartDataUnits,
    stacked,
    chartQualifierLabel,
    subCategoryLabel,
    isChartDataLabelOn,
    isChartDataLabelUnderlined,
    chartDataLabelColor,
    isChartDataLabelItalic,
    isChartDataLabelBold,
    chartDataLabelFontSize,
    chartDataLabelFontFamily,
    chartDataLabelBackgroundColor,
    chartDataLabelBackgroundSize,
    chartDataLabelFormat,
    chartDataLabelDecimalPlaces,
  );

  let uniqueCategories: string[] = [];
  uniqueCategories =
    chartType === "donut" || chartType === "pie"
      ? chartData
          .map((item) => item.category)
          .filter((category): category is string => !!category)
      : series.map((item) => item.yKey);

  uniqueCategories
    .filter((category): category is string => !!category)
    .map((category) => ({
      type:
        chartType === "donut"
          ? "donut"
          : chartType === "pie"
            ? "pie"
            : chartType,
      xKey: "timePeriod",
      yKey: category,
      calloutLabelKey: category,

      angleKey: "value",
      innerRadiusRatio: 0.6,
      stacked: stacked,
      label: {
        enabled: isChartDataLabelOn,
        textDecoration: isChartDataLabelUnderlined ? "underline" : "none",
        color: chartDataLabelColor,
        fontStyle: isChartDataLabelItalic ? "italic" : "normal",
        fontWeight: isChartDataLabelBold ? "bold" : "normal",
        fontSize: chartDataLabelFontSize,
        fontFamily: chartDataLabelFontFamily,
        padding: 20,
        formatter: getFormatterFunction(
          chartDataLabelFormat,
          chartDataLabelDecimalPlaces,
        ),
      },
    }));

  const chartOptionsWithData = { ...chartOptions, series, data: chartData };
  console.log("Chart options with data:", chartOptionsWithData);

  const updateFilter = (filter: Filter) => {
    updateChart({ ...chart, filter });
  };

  return (
    <div
      ref={chartWrapperRef}
      // className="chart-wrapper"
      // data-x="0"
      // data-y="0"
      style={{
        // transform: `translate(${x}px, ${y}px)`,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        ...(active
          ? {
              border: "1px solid #0D99FF",
            }
          : {}),
      }}
      onPointerDown={(event) => {
        event.stopPropagation();
        onMouseDown(index);
      }}
      onClick={() => onClick(index)}
    >
      {active && (
        <div
          className="dateOptionsWrapper"
          style={{
            position: "absolute",
            top: "-37px",
            width: "100%",
            display: "flex", // Use flexbox
            flexDirection: "row", // Align items in a row
            justifyContent: "space-between", // Adjust this as needed
            alignItems: "center", // Align items vertically in the center
            zIndex: 9,
          }}
        >
          <ChartRenderDateOptions
            years={chart.chartYears}
            filter={chart.filter}
            updateFilter={updateFilter}
            chartDateSort={chartDateSort}
            setChartDateSort={setChartDateSort}
            chartDataUnits={chartDataUnits}
            setChartDataUnits={setChartDataUnits}
          />
        </div>
      )}
      <div className="w-full h-full chart-parent-div" id={`chart-${index}`}>
        {showChartHeader && (
          <div
            style={{
              width: "100%",
              backgroundColor: chartHeaderBackgroundColor,
              color: chartHeaderTextColor,
              fontFamily: chartHeaderFontFamily,
              fontSize: chartHeaderFontSize,
              fontStyle: chartHeaderTextStyle.includes("italic")
                ? "italic"
                : "normal",
              fontWeight: chartHeaderTextStyle.includes("bold")
                ? "bold"
                : "normal",
              textDecoration: chartHeaderTextStyle.includes("underline")
                ? "underline"
                : "none",
              textAlign: chartHeaderTextAlignment as TextAlign,
              borderTop:
                chartHeaderBorder === "top" || chartHeaderBorder === "full"
                  ? `1px solid ${chartHeaderBorderColor}`
                  : "none",
              borderBottom:
                chartHeaderBorder === "bottom" || chartHeaderBorder === "full"
                  ? `1px solid ${chartHeaderBorderColor}`
                  : "none",
              borderLeft:
                chartHeaderBorder === "full"
                  ? `1px solid ${chartHeaderBorderColor}`
                  : "none",
              borderRight:
                chartHeaderBorder === "full"
                  ? `1px solid ${chartHeaderBorderColor}`
                  : "none",
              zIndex: 10,
              height: "30px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {chartHeader}
          </div>
        )}
        <div
          className="text-left italic"
          style={{ fontSize: "8px", height: "10px", marginTop: "3px" }}
        >
          ($ {chartDataUnits})
        </div>
        <div style={{ height: "calc(100% - 43px)" }}>
          <AgChartsReact
            className="custom-legend-label"
            container={document.querySelector("#chartElement")}
            options={chartOptionsWithData as any}
            data={chartOptionsWithData.data}
            series={series}
          />
        </div>
        {active && (
          <div
            className="absolute z-9"
            style={{
              top: "-2px",
              left: "-2px",
              right: "-2px",
              bottom: "-2px",
            }}
          >
            <Icons.Square
              className="absolute"
              style={{ top: "-1px", left: "-1px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-1px", left: "-1px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ top: "-1px", right: "-1px" }}
            />
            <Icons.Square
              className="absolute"
              style={{ bottom: "-1px", right: "-1px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
