"use client";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { ScrollWrapper } from "@/components";
import {
  GetActiveExportOutput,
  ParsedAnalysisData,
} from "../../../../../core/model/api";
// eslint-disable-next-line import/no-internal-modules
import { Add } from "@carbon/react/icons";
// eslint-disable-next-line import/no-internal-modules
import "@carbon/charts-react/styles.css";
import { ChartEditor, TableEditor } from "./components";
import { slideMasterDisplay } from "utils";
import interact from "interactjs";
// eslint-disable-next-line import/no-internal-modules
import "ag-grid-community/styles/ag-grid.css";
// eslint-disable-next-line import/no-internal-modules
import "ag-grid-community/styles/ag-theme-balham.css";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { domToDataUrl, domToPng } from "modern-screenshot";
import { Grid } from "./grid";
import { useHotkeys } from "react-hotkeys-hook";
import { Chart } from "./chart";
import { ChartOptions } from "./types";
import { GetAvailableAnalysesOutput } from "../../../../../core/model/api/analysis";
import { getAnalysisExecution } from "@/api";
import { useParams } from "next/navigation";
import { useSlidesStore } from "@/providers";
import { Table, Chart as ChartType } from "@/stores";
import { DataUnits } from "./types";

interface Axis {
  type: "category" | "number";
  position?: "bottom" | "left" | "top" | "right";
  label?: any;
  min?: number;
  max?: number;
  gridline?: any;
}

type Props = {
  activeExport: GetActiveExportOutput;
  triggerScreenshot: boolean;
  setTriggerScreenshot: (value: boolean) => void;
  activeEditor:
    | {
        type: "table" | "chart";
        id: number;
      }
    | undefined;
  setActiveEditor: (
    value: { type: "table" | "chart"; id: number } | undefined,
  ) => void;
  analyses: GetAvailableAnalysesOutput;
  getRef: any;
  setRef: any;
  selectedAspectRatio: any;
  onAspectRatioContainerWidthChange: (value: any) => void;
  addHorizontalGuide: () => void;
  addVerticalGuide: () => void;
  clearAllGuides: () => void;
  guides: { type: "horizontal" | "vertical"; position: number }[];
  setGuides: (
    value: { type: "horizontal" | "vertical"; position: number }[],
  ) => void;
  prepExportDimensions: boolean;
  setPrepExportDimensions: (value: boolean) => void;
};

export const AnalysisRunScreen = ({
  analyses,
  triggerScreenshot,
  setTriggerScreenshot,
  activeEditor,
  setActiveEditor,
  setRef,
  selectedAspectRatio,
  guides,
  prepExportDimensions,
}: Props) => {
  const [activeRef, setActiveRef] = useState<string | undefined>();

  const {
    activeSlide: activeSlideStore,
    slides,
    setActiveSlide: updateActiveSlide,
    updateThumbnail,
    addSlide,
    addTable,
    addChart,
    removeChart,
    removeTable,
    updateTable,
    updateChart,
    updateDimensions,
  } = useSlidesStore((state) => state);

  const tables = slides[activeSlideStore].tables;
  const charts = slides[activeSlideStore].charts;

  const [gridApi, setGridApi] = useState<GridApi | undefined>();

  const aspectRatioContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log("prepExportDimensions changed:", prepExportDimensions);
  }, [prepExportDimensions]);

  interface Dimensions {
    yOffset?: number;
    xOffset?: number;
    exportWidth?: number;
    exportHeight?: number;
    exportXOffset?: number;
    exportYOffset?: number;
  }

  const updateExportDimensions = useCallback(
    (isGrid: boolean, index: number, dimensions: Partial<Dimensions>) => {
      if (isGrid) {
        updateDimensions(true, index, dimensions);
      } else {
        updateDimensions(false, index, dimensions);
      }
    },
    [updateDimensions],
  );

  const [containerWidth, setContainerWidth] = useState<any>();
  const [containerHeight, setContainerHeight] = useState<any>();

  useEffect(() => {
    const calculateDimensions = () => {
      if (aspectRatioContainerRef.current) {
        const parentElement = aspectRatioContainerRef.current.parentElement;
        if (parentElement) {
          const parentWidth = parentElement.clientWidth;
          const parentHeight = parentElement.clientHeight;

          const aspectRatioWidth = selectedAspectRatio === "4:3" ? 10 : 13.33;
          const aspectRatioHeight = selectedAspectRatio === "4:3" ? 7.5 : 7.5;

          const widthRatio = parentWidth / aspectRatioWidth;
          const heightRatio = parentHeight / aspectRatioHeight;
          const ratio = Math.min(widthRatio, heightRatio);
          const newContainerWidth = aspectRatioWidth * ratio;
          const newContainerHeight = aspectRatioHeight * ratio;

          setContainerWidth(newContainerWidth);
          setContainerHeight(newContainerHeight);

          aspectRatioContainerRef.current.style.width = `${newContainerWidth}px`;
          aspectRatioContainerRef.current.style.height = `${newContainerHeight}px`;

          console.log(
            "containerWidth:",
            containerWidth,
            "containerHeight:",
            containerHeight,
          );
        }
      }
    };

    calculateDimensions();

    const handleResize = () => {
      calculateDimensions();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedAspectRatio, containerWidth, containerHeight]);

  useEffect(() => {
    if (prepExportDimensions) {
      // Move the aspect ratio container dimension update logic inside the callback
      if (aspectRatioContainerRef.current) {
        aspectRatioContainerRef.current.style.width =
          selectedAspectRatio === "4:3" ? "10in" : "13.33in";
        aspectRatioContainerRef.current.style.height =
          selectedAspectRatio === "4:3" ? "7.5in" : "7.5in";
        aspectRatioContainerRef.current.style.transform = "translateX(-9999px)";
      }

      const updateElementDimensions = (
        elementType: string,
        callback: () => void,
      ) => {
        const elements = document.querySelectorAll(
          `.resize-drag.${elementType}-element`,
        );

        for (const [index, element] of elements.entries()) {
          const target = element as HTMLElement;
          const parent = target.parentElement;

          if (parent) {
            const aspectRatioWidth = selectedAspectRatio === "4:3" ? 10 : 13.33;
            const aspectRatioHeight = selectedAspectRatio === "4:3" ? 7.5 : 7.5;

            const xOffset = Number(target.dataset.x || 0);
            const yOffset = Number(target.dataset.y || 0);

            const xOffsetInches = (xOffset / containerWidth) * aspectRatioWidth;
            const yOffsetInches =
              (yOffset / containerHeight) * aspectRatioHeight;

            const preResizeXOffsetPercent =
              target.getBoundingClientRect().width / containerWidth;
            const preResizeYOffsetPercent =
              target.getBoundingClientRect().height / containerHeight;

            const widthInches = preResizeXOffsetPercent * aspectRatioWidth;
            console.log(
              "widthInches:",
              target.getBoundingClientRect().width,
              "containerWidth:",
              containerWidth,
            );
            const heightInches = preResizeYOffsetPercent * aspectRatioHeight;
            console.log(
              "heightInches:",
              target.getBoundingClientRect().height,
              "containerHeight:",
              containerHeight,
            );

            const exportDimensions: Partial<Dimensions> = {
              exportWidth: widthInches,
              exportHeight: heightInches,
              exportXOffset: xOffsetInches,
              exportYOffset: yOffsetInches,
            };

            target.dataset.exportWidth =
              exportDimensions.exportWidth?.toString() || "";
            target.dataset.exportHeight =
              exportDimensions.exportHeight?.toString() || "";
            target.dataset.xOffsetInches =
              exportDimensions.exportXOffset?.toString() || "";
            target.dataset.yOffsetInches =
              exportDimensions.exportYOffset?.toString() || "";

            // Update the width and height of the .resize-drag element
            target.style.width = `${widthInches}in`;
            target.style.height = `${heightInches}in`;

            updateExportDimensions(
              elementType === "grid",
              index,
              exportDimensions,
            );
            console.log(
              `Updated export dimensions for ${elementType} ${index}:`,
              `exportWidth: ${exportDimensions.exportWidth}, exportHeight: ${exportDimensions.exportHeight}, exportXOffset: ${exportDimensions.exportXOffset}, exportYOffset: ${exportDimensions.exportYOffset}`,
            );
          }
        }

        callback();
      };

      if (aspectRatioContainerRef.current) {
        aspectRatioContainerRef.current.style.width =
          selectedAspectRatio === "4:3" ? "10in" : "13.33in";
        aspectRatioContainerRef.current.style.height = "7.5in";

        setTimeout(() => {
          updateElementDimensions("grid", () => {
            updateElementDimensions("chart", () => {
              // Callback after updating dimensions
            });
          });
        }, 10);
      }
    } else {
      if (aspectRatioContainerRef.current) {
        aspectRatioContainerRef.current.style.transform = "none";

        const parentElement = aspectRatioContainerRef.current.parentElement;
        if (parentElement) {
          const parentWidth = parentElement.clientWidth;
          const parentHeight = parentElement.clientHeight;

          const aspectRatioWidth = selectedAspectRatio === "4:3" ? 10 : 13.33;
          const aspectRatioHeight = selectedAspectRatio === "4:3" ? 7.5 : 7.5;

          const widthRatio = parentWidth / aspectRatioWidth;
          const heightRatio = parentHeight / aspectRatioHeight;
          const ratio = Math.min(widthRatio, heightRatio);
          const containerWidth = aspectRatioWidth * ratio;
          const containerHeight = aspectRatioHeight * ratio;

          aspectRatioContainerRef.current.style.width = `${containerWidth}px`;
          aspectRatioContainerRef.current.style.height = `${containerHeight}px`;

          // Pre-resize rebasing logic
          const elements = document.querySelectorAll(".resize-drag");
          for (const element of elements) {
            const target = element as HTMLElement;

            const xOffsetInches = Number(target.dataset.xOffsetInches);
            const yOffsetInches = Number(target.dataset.yOffsetInches);

            const preResizeXOffset =
              (xOffsetInches / aspectRatioWidth) * containerWidth;
            console.log("preResizeXOffset:", preResizeXOffset);
            const preResizeYOffset =
              (yOffsetInches / aspectRatioHeight) * containerHeight;
            console.log("preResizeYOffset:", preResizeYOffset);

            target.style.transform = `translate(${preResizeXOffset}px, ${preResizeYOffset}px)`;
            target.dataset.x = preResizeXOffset.toString();
            target.dataset.y = preResizeYOffset.toString();

            const exportWidth = Number(target.dataset.exportWidth || 0);
            const exportHeight = Number(target.dataset.exportHeight || 0);

            const rebaseWidth =
              (exportWidth / aspectRatioWidth) * containerWidth;
            const rebaseHeight =
              (exportHeight / aspectRatioHeight) * containerHeight;

            target.style.width = `${rebaseWidth}px`;
            target.style.height = `${rebaseHeight}px`;
          }
        }
      }
    }
  }, [
    prepExportDimensions,
    selectedAspectRatio,
    updateExportDimensions,
    containerHeight,
    containerWidth,
  ]);

  const [showThumbnail, setShowThumbnail] = useState(false);

  useEffect(() => {
    if (prepExportDimensions) {
      setShowThumbnail(true);
    } else {
      setTimeout(() => {
        setShowThumbnail(false);
      }, 250);
    }
  }, [prepExportDimensions]);

  useHotkeys(
    "ctrl+s, meta+s",
    () => {
      const slideParent = document.querySelector("#slide-parent-div")!;
      domToDataUrl(slideParent).then((url) => {
        updateThumbnail(url);
      });
    },
    { preventDefault: true },
  );

  useHotkeys("delete", () => {
    if (activeRef !== undefined) {
      const isTable = activeRef.includes("table");
      const elementIndex = Number.parseInt(activeRef.split("-")[1]);

      if (isTable) {
        removeTable(elementIndex);
      } else {
        removeChart(elementIndex);
      }

      setActiveRef(undefined);
    }
  });

  useHotkeys("esc", () => {
    setActiveRef(undefined);
  });

  useEffect(() => {
    updateActiveSlide(slides.length > 0 ? slides.length - 1 : 0);
  }, [slides.length, updateActiveSlide]);

  const handleSlideChange = (index: number) => {
    setActiveRef(undefined);
    updateActiveSlide(index);
  };

  const onAddSlideClick = () => {
    addSlide();
    setActiveRef(undefined);
  };

  const [chartQualifierLabel, setChartQualifierLabel] = useState<any>();
  const [subCategoryLabel, setSubCategoryLabel] = useState<any>();

  const [qualifierLabel, setQualifierLabel] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const memoizedQualifierLabel = useMemo(() => {
    return qualifierLabel ?? "";
  }, [qualifierLabel]);
  // Table container inputs
  const [borderRadius, setBorderRadius] = useState<string>("0px");

  // Chart data label
  const [chartDataLabelDecimalPlaces, setChartDataLabelDecimalPlaces] =
    useState<any>();
  const [chartDataLabelBackgroundColor, setChartDataLabelBackgroundColor] =
    useState<any>("#042c5b");
  const [chartDataLabelColor, setChartDataLabelColor] =
    useState<any>("#FFFFFF");
  const [chartDataLabelBackgroundSize, setChartDataLabelBackgroundSize] =
    useState<any>();
  const [chartDataLabelFontFamily, setChartDataLabelFontFamily] =
    useState<any>("Calibri");
  const [chartDataLabelFontSize, setChartDataLabelFontSize] =
    useState<any>("10");
  const [isChartDataLabelOn, setIsChartDataLabelOn] = useState<any>(true);
  const [chartDataLabelFormat, setChartDataLabelFormat] = useState<any>();
  const [
    isChartDataLabelBackgroundTransparent,
    setIsChartDataLabelBackgroundTransparent,
  ] = useState<any>();
  const [isChartDataLabelBold, setIsChartDataLabelBold] = useState<any>();
  const [isChartDataLabelItalic, setIsChartDataLabelItalic] = useState<any>();
  const [isChartDataLabelUnderlined, setIsChartDataLabelUnderlined] =
    useState<any>();
  const [decimalPlaces, setDecimalPlaces] = useState<any>();

  // Additional table CSS variable items
  const [backgroundColor, setBackgroundColor] = useState<any>();
  const [dataColor, setDataColor] = useState<any>();
  const [rowHoverColor, setRowHoverColor] = useState<any>("none");
  const [columnHoverColor, setColumnHoverColor] = useState<any>("none");
  const [rangeSelectionBorderColor, setRangeSelectionBorderColor] =
    useState<any>();
  const [rangeSelectionBorderStyle, setRangeSelectionBorderStyle] =
    useState<any>();
  const [rangeSelectionBackgroundColor, setRangeSelectionBackgroundColor] =
    useState<any>();

  const [bordersSecondary, setBordersSecondary] = useState<any>();
  const [secondaryBorderColor, setSecondaryBorderColor] = useState<any>();

  const [cellHorizontalBorder, setCellHorizontalBorder] = useState<any>();
  const [gridSize, setGridSize] = useState<any>();
  const [rowHeight, setRowHeight] = useState<any>("16px");
  const [headerHeight, setHeaderHeight] = useState<any>("18px");
  const [headerColumnSeparatorDisplay, setHeaderColumnSeparatorDisplay] =
    useState<any>("none");
  const [fontFamily, setFontFamily] = useState<any>();
  const [fontSize, setFontSize] = useState<any>("12px");

  // Table editor header
  const [tableHeader, setTableHeader] = useState<string>("Table header...");
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState<any>();
  const [headerTextColor, setHeaderTextColor] = useState("#44546A");
  const [headerFontFamily, setHeaderFontFamily] = useState<string>("Calibri");
  const [headerFontSize, setHeaderFontSize] = useState<string>("18px");
  const [headerTextStyle, setHeaderTextStyle] = useState<string>("bold");
  const [headerTextAlignment, setHeaderTextAlignment] =
    useState<string>("left");
  const [headerBorder, setHeaderBorder] = useState<any>(
    "border-bottom 1px #D0CECE",
  );
  const [headerBorderColor, setHeaderBorderColor] = useState<any>("#D0CECE");

  // Chart header
  const [showChartHeader, setShowChartHeader] = useState<boolean>(true);
  const [chartHeader, setChartHeader] = useState<string>("Chart header...");
  const [chartDataUnits, setChartDataUnits] = useState<DataUnits>(
    DataUnits.ACTUAL,
  );
  const [chartHeaderBackgroundColor, setChartHeaderBackgroundColor] =
    useState<any>();
  const [chartHeaderTextColor, setChartHeaderTextColor] = useState("#44546A");
  const [chartHeaderFontFamily, setChartHeaderFontFamily] =
    useState<string>("Calibri");
  const [chartHeaderFontSize, setChartHeaderFontSize] = useState<any>("18");
  const [chartHeaderTextStyle, setChartHeaderTextStyle] = useState<any>([
    "bold",
  ]);
  const [chartHeaderTextAlignment, setChartHeaderTextAlignment] =
    useState<string>("left");
  const [chartHeaderBorder, setChartHeaderBorder] = useState<any>("bottom");
  const [chartHeaderBorderColor, setChartHeaderBorderColor] =
    useState<any>("#D0CECE");

  // Chart legend
  const [isLegendEnabled, setIsLegendEnabled] = useState<boolean>(true);
  const [legendPosition, setLegendPosition] = useState<any>("bottom");
  const [legendOrientation, setLegendOrientation] = useState<any>("horizontal");
  const [legendSpacing, setLegendSpacing] = useState<number>(10);

  const [legendItemMarkerSize, setLegendItemMarkerSize] = useState<number>(10);
  const [legendItemMarkerShape, setLegendItemMarkerShape] =
    useState<any>("square");
  const [legendItemMarkerPadding, setLegendItemMarkerPadding] =
    useState<number>(8);
  const [legendItemMarkerStrokeWidth, setLegendItemMarkerStrokeWidth] =
    useState<number>(1);

  const [legendItemLineStrokeWidth, setLegendItemLineStrokeWidth] =
    useState<number>(1);
  const [legendItemLineLength, setLegendItemLineLength] = useState<number>(20);

  const [legendItemLabelColor, setLegendItemLabelColor] = useState<any>();
  const [legendItemLabelFontStyle, setLegendItemLabelFontStyle] =
    useState<any>("normal");
  const [legendItemLabelFontWeight, setLegendItemLabelFontWeight] =
    useState<any>("normal");
  const [legendItemLabelFontSize, setLegendItemLabelFontSize] =
    useState<number>(12);
  const [legendItemLabelFontFamily, setLegendItemLabelFontFamily] =
    useState<string>("Arial");

  const [legendItemPaddingX, setLegendItemPaddingX] = useState<number>(8);
  const [legendItemPaddingY, setLegendItemPaddingY] = useState<number>(8);

  // Grid global styles
  const [gridGlobalBackgroundColor, setGridGlobalBackgroundColor] =
    useState<any>();
  const [globalFontFamily, setGlobalFontFamily] =
    useState<string>("IBM Plex Sans");
  const [globalFontSize, setGlobalFontSize] = useState<string>("11px");

  const [globalTextStyle, setGlobalTextStyle] = useState<string>("regular");

  const [globalBorder, setGlobalBorder] = useState<string>("none");
  const [globalBorderColor, setGlobalBorderColor] = useState<string>("#03000F");
  const [oddRowBackgroundColor, setOddRowBackgroundColor] =
    useState<string>("transparent");

  // Column labels

  const [columnLabelTextStyle, setColumnLabelTextStyle] =
    useState<string>("bold");
  const [columnLabelTextDecoration, setColumnLabelTextDecoration] =
    useState<string>("underline");
  const [columnLabelTextAlignment, setColumnLabelTextAlignment] =
    useState<string>("center");
  const [columnLabelColor, setColumnLabelColor] = useState<string>("#03000F");
  const [columnLabelFontSize, setColumnLabelFontSize] =
    useState<string>("12px");
  const [columnLabelBackgroundColor, setColumnLabelBackgroundColor] =
    useState<string>("transparent");

  const [totalRowColor, setTotalRowColor] = useState("black");

  // Table editor cell format
  const [cellFormat, setCellFormat] = useState<string>("Default");
  const [cellFormatTextStyle, setCellFormatTextStyle] =
    useState<string>("regular");
  const [cellFormatTextAlignment, setCellFormatTextAlignment] =
    useState<string>("left");
  const [cellFormatBorder, setCellFormatBorder] = useState<any>("none");
  const [cellFormatBorderColor, setCellFormatBorderColor] = useState<any>();
  const [cellFormatBackgroundColor, setCellFormatBackgroundColor] =
    useState<any>();

  const [cellFormatTextColor, setCellFormatTextColor] = useState<any>();

  // Table editor total & sum row format
  const [totalRowTextStyle, setTotalRowTextStyle] = useState<string>("bold");
  const [totalRowTextAlignment, setTotalRowTextAlignment] =
    useState<string>("left");
  const [totalRowBackgroundColor, setTotalRowBackgroundColor] = useState<any>();
  const [totalRowFontFamily, setTotalRowFontFamily] = useState<any>();
  const [totalRowFontSize, setTotalRowFontSize] = useState<any>();

  const [totalRowTextDecoration, setTotalRowTextDecoration] = useState<any>();
  const [subTotalRowTextStyle, setSubTotalRowTextStyle] = useState<any>("bold");
  const [subTotalRowTextAlignment, setSubTotalRowTextAlignment] =
    useState<any>();
  const [subTotalRowBorder, setSubTotalRowBorder] = useState<any>();
  const [subTotalRowBorderTop, setSubTotalRowBorderTop] =
    useState<any>("solid 1px");
  const [subTotalRowBorderBottom, setSubTotalRowBorderBottom] = useState<any>();
  const [subTotalRowBorderColor, setSubTotalRowBorderColor] = useState<any>();
  const [subTotalRowBackgroundColor, setSubTotalRowBackgroundColor] =
    useState<any>();
  const [subTotalRowTextDecoration, setSubTotalRowTextDecoration] =
    useState<any>();
  const [subTotalRowColor, setSubTotalRowColor] = useState<any>();
  const [subTotalRowFontSize, setSubTotalRowFontSize] = useState<any>();

  // Chart editor chart area
  const [chartBackgroundColor, setChartBackgroundColor] = useState<any>();
  const [isChartBackgroundTransparent, setIsChartBackgroundTransparent] =
    useState<any>(true);
  const [chartBorder, setChartBorder] = useState<any>("none");
  const [chartBorderColor, setChartBorderColor] = useState<any>("#03000F");
  const [isAxisGridLines, setIsAxisGridLines] = useState<any>(false);
  const [chartType, setChartType] = useState<any>("bar");
  const [stacked, setStacked] = useState<boolean>(false);

  const handleChartTypeChange = (newType: string, stacked: boolean) => {
    setChartType(newType);
    setStacked(stacked);
    updateChartOptions({ type: newType, stacked });
  };

  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    resizable: true,
    legend: {
      enabled: isLegendEnabled,
      position: legendPosition,
      orientation: legendOrientation,
      spacing: legendSpacing,
      item: {
        marker: {
          size: legendItemMarkerSize,
          shape: legendItemMarkerShape,
          padding: legendItemMarkerPadding,
          strokeWidth: legendItemMarkerStrokeWidth,
        },
        line: {
          strokeWidth: legendItemLineStrokeWidth,
          length: legendItemLineLength,
        },
        label: {
          color: legendItemLabelColor,
          fontStyle: legendItemLabelFontStyle,
          fontWeight: legendItemLabelFontWeight,
          fontSize: legendItemLabelFontSize,
          fontFamily: legendItemLabelFontFamily,
        },
        // paddingX: legendItemPaddingX,
        // paddingY: legendItemPaddingY,
      },
    },
    autoSizeColumns: ["firstColumn"],
    type: chartType,

    theme: {
      baseTheme: "ag-default",
      palette: {
        fills: [
          // "#03000F", TRANSACTED
          // "#ADC9FF",
          // "#0000FF",
          // "#E5EEFF",
          // "#03000F",
          // "#D30018",

          // SAW MILL
          "#042c5b",
          "#b0bccc",
          "#ec3c44",
          "#8497b0",
          "#004890",
          "#797979",
          "#f8a3a7",
        ],
        strokes: ["black"],
      },
    },
    background: {
      fill: isChartBackgroundTransparent ? "transparent" : chartBackgroundColor,
      border: {
        width: chartBorder === "none" ? 0 : 1,
        color: chartBorderColor,
      },
    },
  });

  const updateChartOptions = useCallback(
    (options: ChartOptions) => {
      setChartOptions((prevOptions) => {
        let updatedOptions: ChartOptions = { ...prevOptions };

        updatedOptions.legend = {
          ...updatedOptions.legend,
          enabled: isLegendEnabled,
          position: legendPosition,
          orientation: legendOrientation,
          spacing: legendSpacing,
          item: {
            marker: {
              size: legendItemMarkerSize,
              shape: legendItemMarkerShape,
              padding: legendItemMarkerPadding,
              strokeWidth: legendItemMarkerStrokeWidth,
            },
            line: {
              strokeWidth: legendItemLineStrokeWidth,
              length: legendItemLineLength,
            },
            label: {
              color: legendItemLabelColor,
              fontStyle: legendItemLabelFontStyle,
              fontWeight: legendItemLabelFontWeight,
              fontSize: legendItemLabelFontSize,
              fontFamily: legendItemLabelFontFamily,
            },
            paddingX: legendItemPaddingX,
            paddingY: legendItemPaddingY,
          },
        };

        // Handle data filter
        if (options.data) {
          updatedOptions.data = options.data;
        }

        // Update axes label options
        updatedOptions.axes = updatedOptions.axes
          ? updatedOptions.axes.map((axis: Axis) => {
              if (axis.type === "category") {
                return {
                  ...axis,
                  label: {
                    ...axis.label,
                    ...options.xAxisLabel,
                    fontSize: 11,

                    enabled: true,
                    formatter: (params: any) => {
                      const seriesKey = params.seriesKey;
                      const value = params.datum[seriesKey];
                      return value === undefined ? "" : value.toFixed(2);
                    },
                  },

                  gridLine: isAxisGridLines
                    ? {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      }
                    : {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      },
                };
              } else if (axis.type === "number") {
                return {
                  ...axis,
                  label: {
                    ...axis.label,
                    ...options.yAxisLabel,
                    fontSize: 11,
                  },
                  min: options.yAxisMin ?? axis.min ?? 0,
                  max: options.yAxisMax ?? axis.max,
                  gridLine: isAxisGridLines
                    ? {
                        style: [
                          {
                            stroke: "rgba(219, 219, 219, 1)",
                            lineDash: [],
                          },
                        ],
                      }
                    : {
                        style: [
                          {
                            stroke: "transparent",
                            lineDash: [],
                          },
                        ],
                      },
                };
              }
              return axis;
            })
          : [
              {
                type: "category",
                position: "bottom",
                label: options.xAxisLabel,
                gridLine: isAxisGridLines
                  ? {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    }
                  : {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    },
              },
              {
                type: "number",
                position: "left",
                label: options.yAxisLabel,
                min: options.yAxisMin ?? 0,
                max: options.yAxisMax,
                gridLine: isAxisGridLines
                  ? {
                      style: [
                        {
                          stroke: "rgba(219, 219, 219, 1)",
                          lineDash: [],
                        },
                      ],
                    }
                  : {
                      style: [
                        {
                          stroke: "transparent",
                          lineDash: [],
                        },
                      ],
                    },
              },
            ];

        // Update chart background options
        updatedOptions.background = {
          ...updatedOptions.background,
          fill: isChartBackgroundTransparent
            ? "transparent"
            : chartBackgroundColor,
          border: {
            width: chartBorder === "none" ? 0 : 1,
            color: chartBorderColor,
          },
        };

        return updatedOptions;
      });
    },
    [
      isAxisGridLines,
      isChartBackgroundTransparent,
      chartBackgroundColor,
      chartBorder,
      chartBorderColor,
      isLegendEnabled,
      legendPosition,
      legendOrientation,
      legendSpacing,
      legendItemMarkerSize,
      legendItemMarkerShape,
      legendItemMarkerPadding,
      legendItemMarkerStrokeWidth,
      legendItemLineStrokeWidth,
      legendItemLineLength,
      legendItemLabelColor,
      legendItemLabelFontStyle,
      legendItemLabelFontWeight,
      legendItemLabelFontSize,
      legendItemLabelFontFamily,
      legendItemPaddingX,
      legendItemPaddingY,
    ],
  );

  // console.log(chartOptions);
  // console.log(updateChartOptions);

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleChartScreenshotTest = async () => {
    const chartElement = document.querySelector("chart-wrapper");
    if (chartElement) {
      try {
        const dataUrlTest = await domToPng(chartElement);

        // Store dataUrlTest in localStorage
        localStorage.setItem("chartScreenshot", dataUrlTest);
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    }
  };

  useEffect(() => {
    if (triggerScreenshot) {
      handleChartScreenshotTest();
      setTriggerScreenshot(false);
    }
  }, [triggerScreenshot, setTriggerScreenshot]);

  const params = useParams<{ dealId: string }>();

  const onTableToggle = (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => {
    getAnalysisExecution(
      params.dealId,
      category,
      subCategory,
      qualifier,
      subQualifiers,
    ).then((data) => {
      const parsedData: ParsedAnalysisData = JSON.parse(data.data);
      const years = parsedData.Annual.columns
        .filter((c: any) => c.startsWith("Annual"))
        .map((c: any) => {
          return new Date(Date.parse(c.split("-")[1].trim())).getFullYear();
        });

      const newTable: Table = {
        dimensions: {
          xOffset: 0,
          yOffset: 0,
          width: 600,
          height: 250,
        },
        tableData: data.data,
        tableYears: years,
        dataUnits: DataUnits.MILLIONS,
        filter: {
          type: "Annual",
          start: new Date(years[0]!, 0, 1)!,
          end: new Date(years.at(-1)!, 11, 31)!,
          rowCount: 10,
        },
      };

      addTable(newTable);

      setActiveEditor({
        type: "table",
        id: tables.length,
      });
    });
  };

  useEffect(() => {
    if (!prepExportDimensions) {
      const slideParent = document.querySelector("#slide-parent-div")!;
      domToDataUrl(slideParent).then((url) => {
        updateThumbnail(url);
      });
    }
  }, [
    slides.length,
    tables,
    charts,
    updateThumbnail,
    prepExportDimensions,
    updateDimensions,
  ]);

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout | undefined;
    const interactables = interact(".resize-drag").draggable({
      listeners: {
        move(event) {
          const target = event.target;
          const x =
            (Number.parseFloat(target.dataset.x || "0") || 0) + event.dx;
          const y =
            (Number.parseFloat(target.dataset.y || "0") || 0) + event.dy;

          target.style.transform = `translate(${x}px, ${y}px)`;
          target.dataset.x = x;
          target.dataset.y = y;

          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
          debounceTimer = setTimeout(() => {
            const slideParent = document.querySelector("#slide-parent-div")!;
            domToDataUrl(slideParent).then((url) => {
              updateThumbnail(url);
            });
          }, 400);
        },
        onend(event) {
          const target = event.target;
          const dimensions = {
            width: event.rect.width,
            height: event.rect.height,
            xOffset: Number.parseFloat(target.dataset.x),
            yOffset: Number.parseFloat(target.dataset.y),
          };
          const isTable = activeRef?.includes(".grid-element");
          const index = Number(target.dataset.index);
          updateDimensions(isTable ? true : false, index, dimensions);
        },
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: true,
        }),
      ],
    });
    return () => {
      interactables.unset();
    };
  }, [updateDimensions, activeRef]);

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout | undefined;
    const interactables = interact(".resize-drag.grid-element").resizable({
      edges: { left: true, right: true, bottom: false, top: false },
      listeners: {
        move(event) {
          const target = event.target;
          let x = Number.parseFloat(target.dataset.x || "0") || 0;
          let y = Number.parseFloat(target.dataset.y || "0") || 0;

          target.style.width = `${event.rect.width}px`;
          target.style.height = `${event.rect.height}px`;

          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.transform = `translate(${x}px, ${y}px)`;
          target.dataset.x = x;
          target.dataset.y = y;

          gridApi?.sizeColumnsToFit();

          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
          debounceTimer = setTimeout(() => {
            const slideParent = document.querySelector("#slide-parent-div")!;
            domToDataUrl(slideParent).then((url) => {
              updateThumbnail(url);
            });
          }, 400);
        },
        onend(event) {
          const target = event.target;
          const dimensions = {
            width: event.rect.width,
            height: event.rect.height,
            xOffset: Number.parseFloat(target.dataset.x),
            yOffset: Number.parseFloat(target.dataset.y),
          };
          const index = Number(target.dataset.index);
          updateDimensions(true, index, dimensions);
        },
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 100, height: 50 },
        }),
      ],
    });
    return () => {
      interactables.unset();
    };
  }, [gridApi, updateDimensions]);

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout | undefined;

    const interactables = interact(".resize-drag.chart-element").resizable({
      edges: { left: true, right: true, bottom: true, top: true },
      listeners: {
        move(event) {
          const target = event.target;
          let x = Number.parseFloat(target.dataset.x || "0") || 0;
          let y = Number.parseFloat(target.dataset.y || "0") || 0;

          target.style.width = `${event.rect.width}px`;
          target.style.height = `${event.rect.height}px`;

          x += event.deltaRect.left;
          y += event.deltaRect.top;

          target.style.transform = `translate(${x}px, ${y}px)`;
          target.dataset.x = x;
          target.dataset.y = y;

          if (debounceTimer) {
            clearTimeout(debounceTimer);
          }
          debounceTimer = setTimeout(() => {
            const slideParent = document.querySelector("#slide-parent-div")!;
            domToDataUrl(slideParent).then((url) => {
              updateThumbnail(url);
            });
          }, 400);
        },
        onend(event) {
          const target = event.target;
          const dimensions = {
            width: event.rect.width,
            height: event.rect.height,
            xOffset: Number.parseFloat(target.dataset.x),
            yOffset: Number.parseFloat(target.dataset.y),
          };
          const index = Number(target.dataset.index);
          updateDimensions(false, index, dimensions);
        },
      },

      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 100, height: 50 },
        }),
      ],
    });

    return () => {
      interactables.unset();
    };
  }, [updateDimensions, updateThumbnail]);

  const onTableClick =
    (index: number) => (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.currentTarget === event.target) {
        setActiveRef(`table-${index}`);
        setActiveEditor({ type: "table", id: index });
      }
    };

  const onChartClick = (index: number) => {
    setActiveRef(`chart-${index}`);
    setActiveEditor({ type: "chart", id: index });
  };

  const handleSlideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      setActiveRef(undefined);
      setActiveEditor(undefined);
    }
  };

  const onChartToggle = (
    category: string,
    subCategory: string,
    qualifier: string,
    subQualifiers: string[],
  ) => {
    getAnalysisExecution(
      params.dealId,
      category,
      subCategory,
      qualifier,
      subQualifiers,
    ).then((data) => {
      const parsedData: ParsedAnalysisData = JSON.parse(data.data);
      const years = parsedData.Annual.columns
        .filter((c) => c.startsWith("Annual"))
        .map((c) => {
          return new Date(Date.parse(c.split("-")[1].trim())).getFullYear();
        });

      const newChart: ChartType = {
        dimensions: {
          xOffset: 0,
          yOffset: 0,
        },
        chartData: data.data,
        chartYears: years,
        filter: {
          type: "Annual",
          start: new Date(years[0]!, 0, 1)!,
          end: new Date(years.at(-1)!, 11, 31)!,
          rowCount: 10,
        },
        chartQualifierLabel: qualifier,
        subCategoryLabel: subCategory,
      };

      addChart(newChart);

      setActiveEditor({
        type: "chart",
        id: slides[activeSlideStore].charts.length,
      });
    });
  };

  const dynamicStyles = `
  .ag-theme-balham .ag-header-cell .ag-header {

    font-style: ${columnLabelTextStyle?.includes("italic") ? "italic" : "normal"};
    font-weight: ${columnLabelTextStyle?.includes("bold") ? "bold" : "normal"};
    text-decoration: ${columnLabelTextDecoration?.includes("underline") ? "underline" : "none"};
  }


  .ag-theme-balham .ag-header-group-cell {
    font-weight: bold;
    justify-content: center;
    
  }

  .ag-theme-balham {
    --ag-row-border-style: dashed;
    --ag-row-border-width: 1px;
    --ag-row-border-color: ${cellFormatBorderColor};
}

  .ag-theme-balham .row-labels-column {
    font-weight: bold;
    justify-content: center;
    border-bottom: none;
  }

  .ag-header-cell.header-cell-border::after,
  .ag-header-group-cell.header-cell-border::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 4px);
    border-bottom: 1px solid black;
  }
  
  .ag-header-cell.ag-column-first.header-cell-border::after {
    left: 0;
    transform: none;
    width: calc(100% - 2px);
  }
  
  .ag-header-cell.ag-column-last.header-cell-border::after {
    left: auto;
    right: 0;
    transform: none;
    width: calc(100% - 2px);
  }
  
  .ag-theme-balham .ag-header-cell.ag-column-first,
  .ag-theme-balham .ag-header-cell.ag-column-first .ag-header-cell-label {
    justify-content: left !important;
  }

  /* Apply dynamic alignment to all but the first column */
  .ag-theme-balham .ag-header-cell:not(.ag-column-first) .ag-header-cell-label {
    justify-content: ${columnLabelTextAlignment};
  }
 
  .ag-theme-balham .ag-row.ag-row-last {
    background-color: ${totalRowBackgroundColor};
    color: ${totalRowColor};
  }

  .ag-theme-balham {
    --ag-font-size: ${globalFontSize};
    --ag-background-color: ${backgroundColor};
    --ag-borders: ${globalBorder};
    --ag-border-color: ${globalBorderColor};
    --ag-header-foreground-color: ${columnLabelColor};
    --ag-header-background-color: ${columnLabelBackgroundColor};
    --ag-odd-row-background-color: ${oddRowBackgroundColor};
    --ag-cell-horizontal-border: ${cellHorizontalBorder};
    --ag-secondary-border-color: ${secondaryBorderColor};
    
    --ag-grid-size: ${gridSize};
    --ag-row-height: ${rowHeight};
    --ag-header-height: ${headerHeight};
    --ag-font-family: ${fontFamily};
  }
`;

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  return (
    <>
      <style>{dynamicStyles}</style>
      <div
        id="height-limit"
        className="flex flex-row flex-1 bg-slideBackground"
      >
        <div className="w-36 bg-white flex flex-col">
          <div className="flex flex-col flex-1 pt-5 mr-2">
            {/* Left content */}
            <ScrollWrapper>
              {/* Slide thumbnails */}
              <div className="space-y-3">
                {slides.map((slide, index) => {
                  return (
                    <div
                      key={`slide-${index}`}
                      className={`w-full ${activeSlideStore === index ? "" : ""}`}
                    >
                      <div className="flex items-center ">
                        <div
                          className={`w-4 h-4 flex items-center justify-center text-gray-600 text-xs ${
                            activeSlideStore === index ? "text-white" : ""
                          }`}
                          style={{
                            borderLeft:
                              activeSlideStore === index
                                ? "2px solid #78a9ff"
                                : "",
                            color:
                              activeSlideStore === index
                                ? "#78a9ff"
                                : "#a2a9b0",
                          }}
                          onMouseEnter={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.borderLeft =
                                "2px solid #dde1e6";
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.borderLeft = "";
                            }
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          className="h-24 bg-white hover:cursor-pointer ml-1"
                          style={{
                            position: "relative",
                            border:
                              activeSlideStore === index
                                ? "2px solid #78a9ff"
                                : "1px solid #dde1e6",
                          }}
                          onMouseEnter={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.border =
                                "2px solid #dde1e6";
                              const indexElement =
                                e.currentTarget.previousSibling;
                              if (indexElement) {
                                (indexElement as HTMLElement).style.borderLeft =
                                  "2px solid #dde1e6";
                              }
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (activeSlideStore !== index) {
                              e.currentTarget.style.border =
                                "1px solid #dde1e6";
                              const indexElement =
                                e.currentTarget.previousSibling;
                              if (indexElement) {
                                (indexElement as HTMLElement).style.borderLeft =
                                  "";
                              }
                            }
                          }}
                          onClick={() => handleSlideChange(index)}
                        >
                          <img
                            alt="Slide Background"
                            src={slide.thumbnail}
                            className="w-full h-full object-cover"
                            style={{ display: "none" }}
                            onLoad={(e) => {
                              e.currentTarget.style.display = "block";
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="flex items-center mr-1 w-full">
                  <div className="w-4 h-4 flex items-center justify-center text-gray-600 text-xs"></div>

                  <div
                    className="h-24 w-32 bg-white border-dashed border border-gray-400 hover:border-blue-600 hover:bg-blue-100 hover:cursor-pointer flex items-center justify-center ml-1"
                    style={{ position: "relative" }}
                    onClick={onAddSlideClick}
                  >
                    <span className="text-sm text-gray-400">Add Slide</span>
                    <Add className="ml-2" fill="#9CA3AF" />
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        </div>
        <div className="flex flex-row bg-slideBackground">
          {/* Left spacer */}
        </div>
        <div className="flex flex-col flex-1 items-center justify-center p-5">
          <div
            id={"slide-parent-div"}
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleSlideClick}
          >
            {slides[activeSlideStore].thumbnail && (
              <img
                src={slides[activeSlideStore].thumbnail}
                alt="Slide Thumbnail"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  display: showThumbnail ? "block" : "none",
                  zIndex: 100,
                }}
              />
            )}
            <div style={{ position: "relative" }}>
              {guides.map(
                (guide: { type: string; position: number }, index: any) => (
                  <div
                    key={index}
                    className="guide-line bg-red flex-grow"
                    style={{
                      position: "absolute",
                      top: 500,
                      left: 500,
                      borderLeft:
                        guide.type === "vertical" ? "1px dashed red" : "none",
                      borderTop:
                        guide.type === "horizontal" ? "1px dashed red" : "none",
                      zIndex: 99,
                      width: guide.type === "vertical" ? "500px" : "100%",
                      height: guide.type === "horizontal" ? "500px" : "100%",
                    }}
                  ></div>
                ),
              )}
            </div>

            <div
              ref={aspectRatioContainerRef}
              className={`aspect-ratio-container ${
                selectedAspectRatio === "4:3"
                  ? "aspect-ratio-4-3"
                  : "aspect-ratio-16-9"
              }`}
              style={{
                position: "absolute",
              }}
              onClick={handleSlideClick}
            >
              <div
                id="displayContent"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {slideMasterDisplay}
                </div>
              </div>
              <div className="absolute top-0 left-0 w-full h-full">
                {tables.map((table: Table, index) => {
                  return (
                    <>
                      <style>{dynamicStyles}</style>
                      <div
                        key={`table-${index}`}
                        className="resize-drag grid-element"
                        style={{
                          position: "absolute",
                          zIndex: activeRef === `table-${index}` ? 2 : 1,
                        }}
                        data-x={table.dimensions.xOffset ?? 0}
                        data-y={table.dimensions.yOffset ?? 0}
                        data-index={index}
                        onMouseDown={onTableClick(index)}
                        onClick={onTableClick(index)}
                      >
                        <Grid
                          className=" ag-theme-balham"
                          style={{
                            display: "flex",
                            flexDirection: "column",

                            flexGrow: 1,
                            zIndex: activeRef === `table-${index}` ? 2 : 1,
                          }}
                          x={table.dimensions.xOffset ?? 0}
                          y={table.dimensions.yOffset ?? 0}
                          data-index={index}
                          onClick={onTableClick(index)}
                          onMouseDown={onTableClick(index)}
                          gridWrapperRef={setRef(`table-${index}`)}
                          index={index}
                          key={`table-${index}`}
                          data={JSON.parse(table.tableData)}
                          onGridReady={onGridReady}
                          active={
                            activeEditor !== undefined &&
                            activeEditor.type === "table" &&
                            activeEditor.id === index
                          }
                          // Global grid styles
                          qualifierLabel={qualifierLabel}
                          onQualifierLabelChange={setQualifierLabel}
                          gridGlobalBackgroundColor={gridGlobalBackgroundColor}
                          onGridGlobalBackgroundColorChange={
                            setGridGlobalBackgroundColor
                          }
                          globalFontFamily={globalFontFamily}
                          onGlobalFontFamilyChange={setGlobalFontFamily}
                          globalFontSize={globalFontSize}
                          onGlobalFontSizeChange={setGlobalFontSize}
                          globalTextStyle={globalTextStyle}
                          onGlobalTextStyleChange={setGlobalTextStyle}
                          globalBorder={globalBorder}
                          onGlobalBorderChange={setGlobalBorder}
                          globalBorderColor={globalBorderColor}
                          onGlobalBorderColorChange={setGlobalBorderColor}
                          oddRowBackgroundColor={oddRowBackgroundColor}
                          onOddRowBackgroundColorChange={
                            setOddRowBackgroundColor
                          }
                          // Column labels
                          columnLabelTextDecoration={columnLabelTextDecoration}
                          columnLabelTextStyle={columnLabelTextStyle}
                          columnLabelTextAlignment={columnLabelTextAlignment}
                          columnLabelBackgroundColor={
                            columnLabelBackgroundColor
                          }
                          columnLabelColor={columnLabelColor}
                          // Header
                          tableHeader={tableHeader}
                          onTableHeaderChange={setTableHeader}
                          headerBackgroundColor={headerBackgroundColor}
                          headerTextColor={headerTextColor}
                          headerFontSize={headerFontSize}
                          headerFontFamily={headerFontFamily}
                          headerTextStyle={headerTextStyle}
                          headerTextAlignment={headerTextAlignment}
                          setActiveEditor={setActiveEditor}
                          headerColumnSeparatorDisplay={
                            headerColumnSeparatorDisplay
                          }
                          headerHeight={headerHeight}
                          // Cell format
                          cellFormat={cellFormat}
                          cellFormatTextStyle={cellFormatTextStyle}
                          cellFormatTextAlignment={cellFormatTextAlignment}
                          cellFormatBorder={cellFormatBorder}
                          cellFormatBorderColor={cellFormatBorderColor}
                          cellFormatBackgroundColor={cellFormatBackgroundColor}
                          cellFormatTextColor={cellFormatTextColor}
                          // Row
                          totalRowTextStyle={totalRowTextStyle}
                          totalRowTextAlignment={totalRowTextAlignment}
                          totalRowBackgroundColor={totalRowBackgroundColor}
                          totalRowFontFamily={totalRowFontFamily}
                          totalRowFontSize={totalRowFontSize}
                          totalRowColor={totalRowColor}
                          subTotalRowTextStyle={subTotalRowTextStyle}
                          subTotalRowTextAlignment={subTotalRowTextAlignment}
                          subTotalRowBorder={subTotalRowBorder}
                          subTotalRowBorderTop={subTotalRowBorderTop}
                          subTotalRowBorderBottom={subTotalRowBorderBottom}
                          subTotalRowBorderColor={subTotalRowBorderColor}
                          subTotalRowBackgroundColor={
                            subTotalRowBackgroundColor
                          }
                          subTotalRowColor={subTotalRowColor}
                          rowHeight={rowHeight}
                          table={table}
                          updateTable={(newTable: Table) =>
                            updateTable(index, newTable)
                          }
                        />
                      </div>
                    </>
                  );
                })}
                {charts.map((chart: ChartType, index) => {
                  return (
                    <div
                      key={`chart-${index}`}
                      className="resize-drag chart-element"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        // width: "600px",
                        // height: "300px",
                        touchAction: "none",
                        boxSizing: "border-box",

                        zIndex: activeRef === `chart-${index}` ? 2 : 1,
                      }}
                      data-x={chart.dimensions.xOffset ?? 500}
                      data-y={chart.dimensions.yOffset ?? 500}
                      onClick={() => onChartClick(index)}
                      onMouseDown={() => onChartClick(index)}
                    >
                      <Chart
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          flexGrow: 1,
                          zIndex: activeRef === `chart-${index}` ? 2 : 1,
                        }}
                        className="placeholder"
                        key={`chart-${index}`}
                        chartWrapperRef={setRef(`chart-${index}`)}
                        active={
                          activeEditor !== undefined &&
                          activeEditor.type === "chart" &&
                          activeEditor.id === index
                        }
                        onClick={() => onChartClick(index)}
                        onMouseDown={() => onChartClick(index)}
                        index={index}
                        data={JSON.parse(chart.chartData)}
                        chartOptions={chartOptions}
                        chartType={chartType}
                        chartDataUnits={chartDataUnits}
                        setChartDataUnits={setChartDataUnits}
                        stacked={stacked}
                        chart={chart}
                        chartQualifierLabel={chart.chartQualifierLabel}
                        onChartQualifierLabelChange={setChartQualifierLabel}
                        subCategoryLabel={chart.subCategoryLabel}
                        onSubCategoryLabelChange={setSubCategoryLabel}
                        updateChart={(updatedChart) =>
                          updateChart(index, updatedChart)
                        }
                        chartHeader={chartHeader}
                        showChartHeader={showChartHeader}
                        setShowChartHeader={setShowChartHeader}
                        onChartHeaderChange={setChartHeader}
                        chartHeaderBackgroundColor={chartHeaderBackgroundColor}
                        onChartHeaderBackgroundColorChange={
                          setChartHeaderBackgroundColor
                        }
                        chartDataLabelDecimalPlaces={
                          chartDataLabelDecimalPlaces
                        }
                        setChartDataLabelDecimalPlaces={
                          setChartDataLabelDecimalPlaces
                        }
                        chartHeaderTextColor={chartHeaderTextColor}
                        onChartHeaderTextColorChange={setChartHeaderTextColor}
                        chartHeaderFontFamily={chartHeaderFontFamily}
                        onChartHeaderFontFamilyChange={setChartHeaderFontFamily}
                        chartHeaderFontSize={chartHeaderFontSize}
                        onChartHeaderFontSizeChange={setChartHeaderFontSize}
                        chartHeaderTextStyle={chartHeaderTextStyle}
                        onChartHeaderTextStyleChange={setChartHeaderTextStyle}
                        chartHeaderTextAlignment={chartHeaderTextAlignment}
                        onChartHeaderTextAlignmentChange={
                          setChartHeaderTextAlignment
                        }
                        chartHeaderBorder={chartHeaderBorder}
                        onChartHeaderBorderChange={setChartHeaderBorder}
                        chartHeaderBorderColor={chartHeaderBorderColor}
                        onChartHeaderBorderColorChange={
                          setChartHeaderBorderColor
                        }
                        isChartDataLabelOn={isChartDataLabelOn}
                        setIsChartDataLabelOn={setIsChartDataLabelOn}
                        isChartDataLabelUnderlined={isChartDataLabelUnderlined}
                        setIsChartDataLabelUnderlined={
                          setIsChartDataLabelUnderlined
                        }
                        isChartDataLabelItalic={isChartDataLabelItalic}
                        setIsChartDataLabelItalic={setIsChartDataLabelItalic}
                        isChartDataLabelBold={isChartDataLabelBold}
                        setIsChartDataLabelBold={setIsChartDataLabelBold}
                        chartDataLabelColor={chartDataLabelColor}
                        setChartDataLabelColor={setChartDataLabelColor}
                        chartDataLabelFontSize={chartDataLabelFontSize}
                        setChartDataLabelFontSize={setChartDataLabelFontSize}
                        chartDataLabelFontFamily={chartDataLabelFontFamily}
                        onChartDataLabelFontFamilyChange={
                          setChartDataLabelFontFamily
                        }
                        chartDataLabelBackgroundColor={
                          chartDataLabelBackgroundColor
                        }
                        setChartDataLabelBackgroundColor={
                          setChartDataLabelBackgroundColor
                        }
                        chartDataLabelBackgroundSize={
                          chartDataLabelBackgroundSize
                        }
                        setChartDataLabelBackgroundSize={
                          setChartDataLabelBackgroundSize
                        }
                        chartDataLabelFormat={chartDataLabelFormat}
                        setChartDataLabelFormat={setChartDataLabelFormat}
                        decimalPlaces={decimalPlaces}
                        setDecimalPlaces={setDecimalPlaces}
                        isLegendEnabled={isLegendEnabled}
                        setIsLegendEnabled={setIsLegendEnabled}
                        legendPosition={legendPosition}
                        setLegendPosition={setLegendPosition}
                        legendOrientation={legendOrientation}
                        setLegendOrientation={setLegendOrientation}
                        legendSpacing={legendSpacing}
                        setLegendSpacing={setLegendSpacing}
                        legendItemMarkerSize={legendItemMarkerSize}
                        setLegendItemMarkerSize={setLegendItemMarkerSize}
                        legendItemMarkerShape={legendItemMarkerShape}
                        setLegendItemMarkerShape={setLegendItemMarkerShape}
                        legendItemMarkerPadding={legendItemMarkerPadding}
                        setLegendItemMarkerPadding={setLegendItemMarkerPadding}
                        legendItemMarkerStrokeWidth={
                          legendItemMarkerStrokeWidth
                        }
                        setLegendItemMarkerStrokeWidth={
                          setLegendItemMarkerStrokeWidth
                        }
                        legendItemLineStrokeWidth={legendItemLineStrokeWidth}
                        setLegendItemLineStrokeWidth={
                          setLegendItemLineStrokeWidth
                        }
                        legendItemLineLength={legendItemLineLength}
                        setLegendItemLineLength={setLegendItemLineLength}
                        legendItemLabelColor={legendItemLabelColor}
                        setLegendItemLabelColor={setLegendItemLabelColor}
                        legendItemLabelFontStyle={legendItemLabelFontStyle}
                        setLegendItemLabelFontStyle={
                          setLegendItemLabelFontStyle
                        }
                        legendItemLabelFontWeight={legendItemLabelFontWeight}
                        setLegendItemLabelFontWeight={
                          setLegendItemLabelFontWeight
                        }
                        legendItemLabelFontSize={legendItemLabelFontSize}
                        setLegendItemLabelFontSize={setLegendItemLabelFontSize}
                        legendItemLabelFontFamily={legendItemLabelFontFamily}
                        setLegendItemLabelFontFamily={
                          setLegendItemLabelFontFamily
                        }
                        legendItemPaddingX={legendItemPaddingX}
                        setLegendItemPaddingX={setLegendItemPaddingX}
                        legendItemPaddingY={legendItemPaddingY}
                        setLegendItemPaddingY={setLegendItemPaddingY}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row bg-slideBackground ">
          {/* Right spacer */}
        </div>
        <div
          className="w-72 flex flex-col bg-[#f4f4f4]"
          style={{ borderLeft: "1px solid #e8e8e8" }}
        >
          {/* Right content */}
          <div className="flex flex-col flex-1">
            {activeEditor !== undefined && activeEditor.type === "chart" && (
              <ChartEditor
                analyses={analyses}
                chartType={chartOptions.series?.[0]?.type || "bar"} // Fallback to 'bar' if undefined
                axisSwitched={false}
                setChartHeaderBorder={setChartHeaderBorder}
                chartHeaderBorder={chartHeaderBorder}
                isChartDataLabelOn={isChartDataLabelOn}
                setIsChartDataLabelOn={setIsChartDataLabelOn}
                chartDataLabelDecimalPlaces={chartDataLabelDecimalPlaces}
                setChartDataLabelDecimalPlaces={setChartDataLabelDecimalPlaces}
                isChartDataLabelBold={isChartDataLabelBold}
                setIsChartDataLabelBold={setIsChartDataLabelBold}
                isChartDataLabelItalic={isChartDataLabelItalic}
                setIsChartDataLabelItalic={setIsChartDataLabelItalic}
                isChartDataLabelUnderlined={isChartDataLabelUnderlined}
                setIsChartDataLabelUnderlined={setIsChartDataLabelUnderlined}
                chartDataLabelFormat={chartDataLabelFormat}
                setChartDataLabelFormat={setChartDataLabelFormat}
                chartDataLabelBackgroundColor={chartDataLabelBackgroundColor}
                setChartDataLabelBackgroundColor={
                  setChartDataLabelBackgroundColor
                }
                chartDataLabelFontFamily={chartDataLabelFontFamily}
                onChartDataLabelFontFamilyChange={setChartDataLabelFontFamily}
                chartDataLabelFontSize={chartDataLabelFontSize}
                setChartDataLabelFontSize={setChartDataLabelFontSize}
                chartDataLabelColor={chartDataLabelColor}
                setChartDataLabelColor={setChartDataLabelColor}
                isChartDataLabelBackgroundTransparent={
                  isChartDataLabelBackgroundTransparent
                }
                onChartDataLabelBackgroundTransparentChange={
                  setIsChartDataLabelBackgroundTransparent
                }
                chartDataLabelBackgroundSize={chartDataLabelBackgroundSize}
                setChartDataLabelBackgroundSize={
                  setChartDataLabelBackgroundSize
                }
                decimalPlaces={decimalPlaces}
                setDecimalPlaces={setDecimalPlaces}
                onUpdateChartOptions={updateChartOptions}
                onChartToggle={onChartToggle}
                onTableToggle={onTableToggle}
                chartQualifierLabel={chartQualifierLabel}
                subCategoryLabel={subCategoryLabel}
                onSubCategoryLabelChange={setSubCategoryLabel}
                onChartQualifierLabelChange={setChartQualifierLabel}
                chartBackgroundColor={chartBackgroundColor}
                onChartBackgroundColorChange={setChartBackgroundColor}
                isChartBackgroundTransparent={isChartBackgroundTransparent}
                onChartBackgroundTransparentChange={
                  setIsChartBackgroundTransparent
                }
                chartBorder={chartBorder}
                onChartBorderChange={setChartBorder}
                chartBorderColor={chartBorderColor}
                onChartBorderColorChange={setChartBorderColor}
                isAxisGridLines={isAxisGridLines}
                onAxisGridLinesChange={setIsAxisGridLines}
                chartOptions={{ ...chartOptions, stacked }}
                onChartTypeChange={handleChartTypeChange}
                stacked={stacked}
                chartHeader={chartHeader}
                showChartHeader={showChartHeader}
                setShowChartHeader={setShowChartHeader}
                onChartHeaderChange={setChartHeader}
                chartHeaderBackgroundColor={chartHeaderBackgroundColor}
                onChartHeaderBackgroundColorChange={
                  setChartHeaderBackgroundColor
                }
                chartHeaderTextColor={chartHeaderTextColor}
                onChartHeaderTextColorChange={setChartHeaderTextColor}
                chartHeaderFontFamily={chartHeaderFontFamily}
                onChartHeaderFontFamilyChange={setChartHeaderFontFamily}
                chartHeaderFontSize={chartHeaderFontSize}
                onChartHeaderFontSizeChange={setChartHeaderFontSize}
                chartHeaderTextStyle={chartHeaderTextStyle}
                onChartHeaderTextStyleChange={setChartHeaderTextStyle}
                chartHeaderTextAlignment={chartHeaderTextAlignment}
                onChartHeaderTextAlignmentChange={setChartHeaderTextAlignment}
                onChartHeaderBorderChange={setChartHeaderBorder}
                chartHeaderBorderColor={chartHeaderBorderColor}
                onChartHeaderBorderColorChange={setChartHeaderBorderColor}
                isLegendEnabled={isLegendEnabled}
                setIsLegendEnabled={setIsLegendEnabled}
                legendPosition={legendPosition}
                setLegendPosition={setLegendPosition}
                legendOrientation={legendOrientation}
                setLegendOrientation={setLegendOrientation}
                legendSpacing={legendSpacing}
                setLegendSpacing={setLegendSpacing}
                legendItemMarkerSize={legendItemMarkerSize}
                setLegendItemMarkerSize={setLegendItemMarkerSize}
                legendItemMarkerShape={legendItemMarkerShape}
                setLegendItemMarkerShape={setLegendItemMarkerShape}
                legendItemMarkerPadding={legendItemMarkerPadding}
                setLegendItemMarkerPadding={setLegendItemMarkerPadding}
                legendItemMarkerStrokeWidth={legendItemMarkerStrokeWidth}
                setLegendItemMarkerStrokeWidth={setLegendItemMarkerStrokeWidth}
                legendItemLineStrokeWidth={legendItemLineStrokeWidth}
                setLegendItemLineStrokeWidth={setLegendItemLineStrokeWidth}
                legendItemLineLength={legendItemLineLength}
                setLegendItemLineLength={setLegendItemLineLength}
                legendItemLabelColor={legendItemLabelColor}
                setLegendItemLabelColor={setLegendItemLabelColor}
                legendItemLabelFontStyle={legendItemLabelFontStyle}
                setLegendItemLabelFontStyle={setLegendItemLabelFontStyle}
                legendItemLabelFontWeight={legendItemLabelFontWeight}
                setLegendItemLabelFontWeight={setLegendItemLabelFontWeight}
                legendItemLabelFontSize={legendItemLabelFontSize}
                setLegendItemLabelFontSize={setLegendItemLabelFontSize}
                legendItemLabelFontFamily={legendItemLabelFontFamily}
                setLegendItemLabelFontFamily={setLegendItemLabelFontFamily}
                legendItemPaddingX={legendItemPaddingX}
                setLegendItemPaddingX={setLegendItemPaddingX}
                legendItemPaddingY={legendItemPaddingY}
                setLegendItemPaddingY={setLegendItemPaddingY}
              />
            )}
            {activeEditor !== undefined && activeEditor.type === "table" && (
              <TableEditor
                analyses={analyses}
                qualifierLabel={qualifierLabel}
                onQualifierLabelChange={setQualifierLabel}
                onTableToggle={onTableToggle}
                onChartToggle={onChartToggle}
                columnLabelTextDecoration={columnLabelTextDecoration}
                //Grid global styles
                gridGlobalBackgroundColor={gridGlobalBackgroundColor}
                onGridGlobalBackgroundColorChange={setGridGlobalBackgroundColor}
                globalFontFamily={globalFontFamily}
                onGlobalFontFamilyChange={setGlobalFontFamily}
                globalFontSize={globalFontSize}
                onGlobalFontSizeChange={setGlobalFontSize}
                globalTextStyle={globalTextStyle}
                onGlobalTextStyleChange={setGlobalTextStyle}
                globalBorder={globalBorder}
                onGlobalBorderChange={setGlobalBorder}
                globalBorderColor={globalBorderColor}
                onGlobalBorderColorChange={setGlobalBorderColor}
                oddRowBackgroundColor={oddRowBackgroundColor}
                onOddRowBackgroundColorChange={setOddRowBackgroundColor}
                // Column labels
                onColumnLabelTextDecorationChange={setColumnLabelTextDecoration}
                onColumnLabelTextStyleChange={setColumnLabelTextStyle}
                onColumnLabelTextAlignmentChange={setColumnLabelTextAlignment}
                columnLabelBackgroundColor={columnLabelBackgroundColor}
                onColumnLabelBackgroundColorChange={
                  setColumnLabelBackgroundColor
                }
                onColumnLabelColorChange={setColumnLabelColor}
                columnLabelTextStyle={columnLabelTextStyle}
                columnLabelTextAlignment={columnLabelTextAlignment}
                columnLabelColor={columnLabelColor}
                columnLabelFontSize={columnLabelFontSize}
                onColumnLabelFontSizeChange={setColumnLabelFontSize}
                headerBackgroundColor={headerBackgroundColor}
                onHeaderBackgroundColorChange={setHeaderBackgroundColor}
                headerTextColor={headerTextColor}
                onHeaderTextColorChange={setHeaderTextColor}
                onBorderRadiusChange={setBorderRadius}
                borderRadius={borderRadius}
                backgroundColor={backgroundColor}
                onBackgroundColorChange={setBackgroundColor}
                dataColor={dataColor}
                onDataColorChange={setDataColor}
                rowHoverColor={rowHoverColor}
                onRowHoverColorChange={setRowHoverColor}
                columnHoverColor={columnHoverColor}
                onColumnHoverColorChange={setColumnHoverColor}
                rangeSelectionBorderColor={rangeSelectionBorderColor}
                onRangeSelectionBorderColorChange={setRangeSelectionBorderColor}
                rangeSelectionBorderStyle={rangeSelectionBorderStyle}
                onRangeSelectionBorderStyleChange={setRangeSelectionBorderStyle}
                rangeSelectionBackgroundColor={rangeSelectionBackgroundColor}
                onRangeSelectionBackgroundColorChange={
                  setRangeSelectionBackgroundColor
                }
                //Table Header
                tableHeader={tableHeader}
                onTableHeaderChange={setTableHeader}
                bordersSecondary={bordersSecondary}
                onBordersSecondaryChange={setBordersSecondary}
                secondaryBorderColor={secondaryBorderColor}
                onSecondaryBorderColorChange={setSecondaryBorderColor}
                cellHorizontalBorder={cellHorizontalBorder}
                onCellHorizontalBorderChange={setCellHorizontalBorder}
                gridSize={gridSize}
                onGridSizeChange={setGridSize}
                rowHeight={rowHeight}
                onRowHeightChange={setRowHeight}
                headerHeight={headerHeight}
                onHeaderHeightChange={setHeaderHeight}
                headerColumnSeparatorDisplay={headerColumnSeparatorDisplay}
                onHeaderColumnSeparatorDisplayChange={
                  setHeaderColumnSeparatorDisplay
                }
                fontFamily={fontFamily}
                onFontFamilyChange={setFontFamily}
                fontSize={fontSize}
                onFontSizeChange={setFontSize}
                headerFontFamily={headerFontFamily}
                onHeaderFontFamilyChange={setHeaderFontFamily}
                headerFontSize={headerFontSize}
                onHeaderFontSizeChange={setHeaderFontSize}
                headerTextStyle={headerTextStyle}
                onHeaderTextStyleChange={setHeaderTextStyle}
                headerTextAlignment={headerTextAlignment}
                onHeaderTextAlignmentChange={setHeaderTextAlignment}
                headerBorder={headerBorder}
                onHeaderBorderChange={setHeaderBorder}
                headerBorderColor={headerBorderColor}
                onHeaderBorderColorChange={setHeaderBorderColor}
                cellFormat={cellFormat}
                onCellFormatChange={setCellFormat}
                cellFormatTextStyle={cellFormatTextStyle}
                onCellFormatTextStyleChange={setCellFormatTextStyle}
                cellFormatTextAlignment={cellFormatTextAlignment}
                onCellFormatTextAlignmentChange={setCellFormatTextAlignment}
                cellFormatBorder={cellFormatBorder}
                onCellFormatBorderChange={setCellFormatBorder}
                cellFormatBorderColor={cellFormatBorderColor}
                onCellFormatBorderColorChange={setCellFormatBorderColor}
                cellFormatBackgroundColor={cellFormatBackgroundColor}
                onCellFormatBackgroundColorChange={setCellFormatBackgroundColor}
                cellFormatTextColor={cellFormatTextColor}
                onCellFormatTextColorChange={setCellFormatTextColor}
                // Total & Sum Rows
                totalRowTextStyle={totalRowTextStyle}
                onTotalRowTextStyleChange={setTotalRowTextStyle}
                totalRowTextAlignment={totalRowTextAlignment}
                onTotalRowTextAlignmentChange={setTotalRowTextAlignment}
                totalRowBackgroundColor={totalRowBackgroundColor}
                onTotalRowBackgroundColorChange={setTotalRowBackgroundColor}
                totalRowFontFamily={totalRowFontFamily}
                onTotalRowFontFamilyChange={setTotalRowFontFamily}
                totalRowFontSize={totalRowFontSize}
                onTotalRowFontSizeChange={setTotalRowFontSize}
                totalRowColor={totalRowColor}
                onTotalRowColorChange={setTotalRowColor}
                totalRowTextDecoration={totalRowTextDecoration}
                onTotalRowTextDecorationChange={setTotalRowTextDecoration}
                subTotalRowTextStyle={subTotalRowTextStyle}
                onSubTotalRowTextStyleChange={setSubTotalRowTextStyle}
                subTotalRowTextAlignment={subTotalRowTextAlignment}
                onSubTotalRowTextAlignmentChange={setSubTotalRowTextAlignment}
                subTotalRowBorder={subTotalRowBorder}
                onSubTotalRowBorderChange={setSubTotalRowBorder}
                subTotalRowBorderTop={subTotalRowBorderTop}
                onSubTotalRowBorderTopChange={setSubTotalRowBorderTop}
                subTotalRowBorderBottom={subTotalRowBorderBottom}
                onSubTotalRowBorderBottomChange={setSubTotalRowBorderBottom}
                subTotalRowBorderColor={subTotalRowBorderColor}
                onSubTotalRowBorderColorChange={setSubTotalRowBorderColor}
                subTotalRowBackgroundColor={subTotalRowBackgroundColor}
                onSubTotalRowBackgroundColorChange={
                  setSubTotalRowBackgroundColor
                }
                subTotalRowTextDecoration={subTotalRowTextDecoration}
                onSubTotalRowTextDecorationChange={setSubTotalRowTextDecoration}
                subTotalRowColor={subTotalRowColor}
                onSubTotalRowColorChange={setSubTotalRowColor}
                subTotalRowFontSize={subTotalRowFontSize}
                onSubTotalRowFontSizeChange={setSubTotalRowFontSize}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
